import React, { useEffect, useState } from 'react';
import { useList, useStore } from 'effector-react';
import {
  cart$,
  cartLength$,
  isCartPopupVisible$,
  latestTotalPriceInfo$,
} from 'models/cart';

import ProductListItem from '../ProductListItem';

const ProductList = () => {
  const cartLength = useStore(cartLength$);
  const { latestTotalPrice, isLoadingLatestTotalPrice } = useStore(
    latestTotalPriceInfo$,
  );

  const isCartPopupVisible = useStore(isCartPopupVisible$);

  const [datePickerOpenId, setDatePickerOpenId] = useState(null);

  useEffect(() => {
    if (!isCartPopupVisible) {
      setDatePickerOpenId(null);
    }
  }, [isCartPopupVisible]);

  return (
    <>
      {useList(cart$, {
        keys: [latestTotalPrice, cartLength, datePickerOpenId],
        fn: (item, index) => {
          const { products } = latestTotalPrice;

          const similarProductInTotalPrices = products?.find(
            (el) => el.id === item.id,
          );

          const isLastItem = index === cartLength - 1;

          return (
            <ProductListItem
              key={item.id}
              itemInCart={item}
              image={item.product.image?.thumb}
              slug={item.product.slug}
              totalPrice={similarProductInTotalPrices?.subtotal}
              quantity={similarProductInTotalPrices?.quantity}
              unitPrice={similarProductInTotalPrices?.unitPrice}
              isLoading={isLoadingLatestTotalPrice}
              isLastItem={isLastItem}
              isProductDisabled={isLoadingLatestTotalPrice}
              setDatePickerOpenId={setDatePickerOpenId}
              datePickerOpenId={datePickerOpenId}
            />
          );
        },
      })}
    </>
  );
};

export default ProductList;
