export const scrollTo = (el, staticAdditionalVal = 0) => {
  const elLeft = el.offsetLeft + el.offsetWidth;
  const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

  // check if element not in view
  if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
    el.parentNode.scrollLeft = elLeft - elParentLeft + staticAdditionalVal;
  } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
    el.parentNode.scrollLeft =
      el.offsetLeft - el.parentNode.offsetLeft + staticAdditionalVal;
  }
};
