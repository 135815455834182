import { useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';

export const useStripePromise = () => {
  return useMemo(() => {
    return loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
      locale: 'en-gb',
    });
  }, []);
};
