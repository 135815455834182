import React from 'react';
import { regions, SMALL_SCREEN } from 'consts';
import { useMatchMedia } from 'hooks/useMatchMedia';
import MobileDropDown from 'layout/Footer/MobileDropDown';

import DesktopRegionsList from './DesktopRegionsList';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const Regions = () => {
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  return isSmallScreen ? (
    <MobileDropDown title="Supported Regions">
      {regions.map(({ name }, i) => (
        <T8y
          key={i}
          variant="t1v1"
          color="primary"
          className={style.footerLink}
        >
          {name}
        </T8y>
      ))}
    </MobileDropDown>
  ) : (
    <DesktopRegionsList />
  );
};

export default Regions;
