import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDatepicker, useDay, useMonth } from '@datepicker-react/hooks';
import clsx from 'clsx';
import { MOBILE_SCREEN } from 'consts';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { getDateInFormat, getDayOfWeek } from 'utils/datesСalculation';
import { capitalizeFirstLetter } from 'utils/formatStrings';

import { useGetLocalizedMonthLabels } from './useGetLocalizedMonthLabels';
import { useGetLocalizedWeeksLabels } from './useGetLocalizedWeeksLabels';

import { ReactComponent as LeftArrow } from 'assets/images/icons/calendarArrow.svg';

import style from './datePickerCalendar.module.scss';

export function DatePickerCalendar({
  calendarState,
  handleDateChange,
  start,
  end,
  minBookingDate,
  minBookingDays,
  maxBookingDate,
  deliveryWeekends = [],
  blockedDatesList = [],
  numberOfMonths = 2,
  edge,
}) {
  const ref = useRef();
  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);
  const shortWeekDaysArray = useGetLocalizedWeeksLabels('EEEEEE');

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    isStartDate,
    focusedDate,
    onDateHover,
    onDateSelect,
    goToDate,
    onDateFocus,
    goToNextMonths,
    goToPreviousMonths,
  } = useDatepicker({
    startDate: start ? parse(start, 'dd/MM/yyyy', new Date()) : null,
    endDate: end ? parse(end, 'dd/MM/yyyy', new Date()) : null,
    focusedInput: calendarState.focusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths: numberOfMonths,
    changeActiveMonthOnSelect: false,
    initialVisibleMonth: new Date(),
    minBookingDate,
    minBookingDays,
    maxBookingDate,
  });

  const monthLabels = useGetLocalizedMonthLabels(activeMonths);
  const currMonthRef = useRef(null);

  //handle show previous months on mobile
  useLayoutEffect(() => {
    if (isMobile && start && currMonthRef.current) {
      goToDate(minBookingDate);
      currMonthRef.current.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToDate, isMobile]);

  const currMonthNumberId = useMemo(() => {
    if (!start) {
      return null;
    }

    const datesArr = start.split('/');

    const month = Number(datesArr[datesArr.length - 2]) - 1;
    const year = datesArr[datesArr.length - 1];

    return `${year}-${month}`;
  }, [start]);

  return (
    <DatepickerContext.Provider
      value={{
        activeMonths,
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        isStartDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
        deliveryWeekends,
        blockedDatesList,
        edge,
        start,
      }}
    >
      <div className={style.picker} ref={ref}>
        <div className={style.monthsWrapper}>
          {activeMonths.map((month, i) => (
            <div
              className={clsx(
                style.monthWrapper,
                style.ethMonthWrapper,
                !isMobile && style.firstMonth,
                !isMobile && style.ethFirstMonth,
              )}
              role="button"
              key={`${month.year}-${month.month}`}
              id={`${month.year}-${month.month}`}
              ref={
                currMonthNumberId === `${month.year}-${month.month}`
                  ? currMonthRef
                  : undefined
              }
            >
              <div className={style.monthHeaderWrapper}>
                {i === 0 && !isMobile && (
                  <div
                    role="button"
                    className={style.monthHeaderArrowLeft}
                    onClick={goToPreviousMonths}
                  >
                    <LeftArrow />
                  </div>
                )}
                <div
                  id={`${i}`}
                  className={style.monthlabel}
                >{`${capitalizeFirstLetter(monthLabels[i])} ${
                  month.year
                }`}</div>
                {i === numberOfMonths - 1 && !isMobile && (
                  <div
                    className={style.monthHeaderArrowRight}
                    onClick={goToNextMonths}
                  >
                    <LeftArrow />
                  </div>
                )}
              </div>
              <div className={style.weeksWrapper}>
                {shortWeekDaysArray.map((item, i) => (
                  <div key={i} className={style.weekdaylabel}>
                    {capitalizeFirstLetter(item)}
                  </div>
                ))}
              </div>
              <Month
                year={month.year}
                month={month.month}
                firstDayOfWeek={firstDayOfWeek}
              />
            </div>
          ))}
        </div>
      </div>
    </DatepickerContext.Provider>
  );
}

function Month({ year, month, firstDayOfWeek }) {
  const { days } = useMonth({
    year,
    month,
    firstDayOfWeek,
    dayLabelFormat: (date) => format(date, 'd'),
  });

  return (
    <div className={style.daysWrapper}>
      {days.map((day, i) => (
        <Day date={day.date} key={`${i}-${day?.dayLabel}`} day={day.dayLabel} />
      ))}
    </div>
  );
}

function Day({ day, date }) {
  const dayRef = useRef(null);

  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);
  const [isDisplayPopover, setIsDisplayPopover] = useState(false);

  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
    deliveryWeekends,
    blockedDatesList,
    start,
    edge,
  } = useContext(DatepickerContext);
  const {
    isSelected,
    isSelectedStartOrEnd,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
    isWithinHoverRange,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });
  useEffect(() => {
    if (
      isSelectedStartOrEnd &&
      !document.activeElement.className.includes('day')
    ) {
      dayRef.current.focus();
    }
  }, [isSelectedStartOrEnd]);

  const isBlocked = useMemo(() => {
    const dayOfWeek = getDayOfWeek(date);

    return (
      deliveryWeekends.includes(dayOfWeek) ||
      blockedDatesList.includes(getDateInFormat(date, 'yyyy-MM-dd'))
    );
  }, [blockedDatesList, date, deliveryWeekends]);

  const isMinDateDisabled = useMemo(() => {
    if (start && disabledDate) {
      return isAfter(date, parse(start, 'dd/MM/yyyy', new Date()));
    }

    return false;
  }, [date, disabledDate, start]);

  const isDateBeforeStart = useMemo(() => {
    if (start) {
      return isBefore(date, parse(start, 'dd/MM/yyyy', new Date()));
    }

    return false;
  }, [date, start]);

  if (!day) {
    return <div className={clsx(style.emptyDay, style.ethEmptyDay)} />;
  }

  return (
    <div
      onMouseEnter={() => {
        if (
          !isMobile &&
          !isBlocked &&
          !isSelected &&
          !isSelectedStartOrEnd &&
          !disabledDate
        ) {
          setIsDisplayPopover(true);
        }
        onMouseEnter();
      }}
      onMouseLeave={() => setIsDisplayPopover(false)}
      className={clsx(style.day, style.ethDay, {
        [style.disabled]: disabledDate,
        [style.blocked]: isBlocked || isMinDateDisabled,
        [style.range]: isSelected || isWithinHoverRange,
        [style.dayselected]: isSelectedStartOrEnd,
      })}
      onClick={() => {
        if (isBlocked) {
          return;
        }

        onClick();
        setIsDisplayPopover(false);
      }}
      onKeyDown={onKeyDown}
      tabIndex={tabIndex}
      ref={dayRef}
      role="button"
    >
      <span>{`${day < 10 ? '0' : ''}${day}`}</span>
      {isDisplayPopover && (
        <div className={style.datePopover}>
          <div className={style.datePopoverInner}>
            <span>
              {edge === 'start' || isDateBeforeStart
                ? 'Choose start date'
                : 'Choose end date'}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

const DatepickerContext = React.createContext();
