import { DEFAULT_COMPANY_TAG } from 'consts';

export function capitalizeFirstLetter(str) {
  if (!str) {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getQueryParam = (search, key) => {
  return new URLSearchParams(search).get(key);
};

export const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    return document.execCommand('copy');
  } catch (err) {
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
};

export const getSeoAltTags = ({ product = {} }) => {
  const title = (product && product.name) || '';

  if (!title) {
    return null;
  }

  const formattedTitle = title.toString().split('-')[0].trim();

  return `${formattedTitle} ${DEFAULT_COMPANY_TAG}`;
};

export const stripHtml = (html) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};
