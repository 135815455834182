import React from 'react';
import SliderS from 'react-slick';
import clsx from 'clsx';

import { Row } from 'ui/Grid/Row';

import { ReactComponent as Arrow } from 'assets/images/icons/arrow.svg';
import { ReactComponent as BoldArrow } from 'assets/images/icons/boldArrow.svg';
import { ReactComponent as OrangeArrow } from 'assets/images/icons/orangeArrow.svg';

import 'slick-carousel/slick/slick.css';
import style from './slider.module.scss';

const getArrow = (boldArrows, orangeArrows) => {
  if (orangeArrows) {
    return <OrangeArrow />;
  }

  if (boldArrows) {
    return <BoldArrow />;
  }

  return <Arrow />;
};

export const Slider = React.forwardRef(
  (
    {
      children,
      className,
      customArrowsClassName,
      dotsWrapperClassName,
      dotsClassName,
      hero = false,
      shortNav,
      isSmallArrows,
      boldArrows,
      onArrowClickCallback,
      onArrowMouseLeave,
      toggleActiveClass,
      prevArrowClassName,
      nextArrowClassName,
      isAlwaysShowSlider,
      isStartPositioning,
      afterChangeCallback,
      orangeArrows,
      ...settings
    },
    ref,
  ) => {
    const defaultSettings = {
      infinite: true,
      slidesToScroll: 1,
      adaptiveHeight: false,
      autoplay: false,
      autoplaySpeed: 4000,
      centerPadding: 0,
      afterChange: (index) => {
        if (afterChangeCallback) {
          afterChangeCallback(index);
        }
      },
      nextArrow: (
        <NextArrow
          hero={hero}
          shortNav={shortNav}
          customArrowsClassName={clsx(
            isSmallArrows && style.smallSliderArrows,
            customArrowsClassName,
            nextArrowClassName,
          )}
          onArrowClickCallback={onArrowClickCallback}
          onArrowMouseLeave={onArrowMouseLeave}
          toggleActiveClass={toggleActiveClass}
          boldArrows={boldArrows}
          orangeArrows={orangeArrows}
        />
      ),
      prevArrow: (
        <PrevArrow
          hero={hero}
          shortNav={shortNav}
          customArrowsClassName={clsx(
            isSmallArrows && style.smallSliderArrows,
            customArrowsClassName,
            prevArrowClassName,
          )}
          onArrowClickCallback={onArrowClickCallback}
          toggleActiveClass={toggleActiveClass}
          onArrowMouseLeave={onArrowMouseLeave}
          boldArrows={boldArrows}
          orangeArrows={orangeArrows}
        />
      ),
      dotsClass: clsx(dotsWrapperClassName, style.customSlickDotsWrapper),
      customPaging: (i) => (
        <div className={clsx(dotsClassName, style.sliderCustomDots)} key={i} />
      ),
    };

    if (children.length < settings.slidesToShow && !isAlwaysShowSlider)
      return (
        <Row justify={isStartPositioning ? 'start' : 'center'} noWrap>
          {children}
        </Row>
      );

    return (
      <SliderS
        ref={ref}
        {...defaultSettings}
        {...settings}
        className={clsx(style.slider, className)}
      >
        {children}
      </SliderS>
    );
  },
);

export function NextArrow({
  onClick,
  hero,
  shortNav,
  customArrowsClassName,
  boldArrows,
  onArrowClickCallback,
  onArrowMouseLeave,
  toggleActiveClass,
  orangeArrows,
}) {
  return (
    <div
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }

        if (onArrowClickCallback) {
          onArrowClickCallback(e);
        }
      }}
      onTouchStart={toggleActiveClass}
      onTouchEnd={toggleActiveClass}
      onMouseLeave={onArrowMouseLeave}
      className={clsx(
        style.arrowNext,
        hero && style.arrowNextHero,
        shortNav && style.arrowNextShort,
        customArrowsClassName,
      )}
      data-testid="sliderNextArrow"
    >
      {getArrow(boldArrows, orangeArrows)}
    </div>
  );
}
export function PrevArrow({
  onClick,
  hero,
  shortNav,
  customArrowsClassName,
  boldArrows,
  orangeArrows,
  onArrowClickCallback,
  onArrowMouseLeave,
  toggleActiveClass,
}) {
  return (
    <div
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }

        if (onArrowClickCallback) {
          onArrowClickCallback(e);
        }
      }}
      onMouseLeave={onArrowMouseLeave}
      onTouchStart={toggleActiveClass}
      onTouchEnd={toggleActiveClass}
      className={clsx(
        style.arrowPrev,
        hero && style.arrowPrevHero,
        shortNav && style.arrowPrevShort,
        customArrowsClassName,
      )}
      data-testid="sliderPrevArrow"
    >
      {getArrow(boldArrows, orangeArrows)}
    </div>
  );
}
