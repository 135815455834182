import React, { useMemo } from 'react';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { regions } from 'consts';
import { useSectionAutoCollapsing } from 'layout/Footer/useSectionAutoCollapsing';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const DesktopRegionsList = () => {
  const disclosure = useDisclosureState();

  useSectionAutoCollapsing(disclosure);

  const { regionsForFirstRender, restRegions } = useMemo(
    () => ({
      regionsForFirstRender: regions.slice(0, 8),
      restRegions: regions.slice(8),
    }),
    [],
  );

  return (
    <div>
      <T8y variant="t1v1" mb2 bold className={style.title}>
        Supported Regions
      </T8y>
      <div>
        {regionsForFirstRender.map(({ name, url, state }, i) => (
          <T8y
            key={i}
            variant="t2v1"
            color="primary"
            className={style.footerLink}
          >
            {name}
          </T8y>
        ))}
      </div>
      {!!restRegions.length && (
        <>
          <DisclosureContent {...disclosure} className={style.restTrainings}>
            {restRegions.map(({ name }, i) => (
              <T8y
                key={i}
                variant="t2v1"
                color="primary"
                className={style.footerLink}
              >
                {name}
              </T8y>
            ))}
          </DisclosureContent>
          <Disclosure {...disclosure}>
            <T8y
              variant="t2v1"
              color="primary"
              mt2
              pointer
              className={style.footerLink}
            >
              {disclosure.visible ? 'Show less' : 'Show more'}...
            </T8y>
          </Disclosure>
        </>
      )}
    </div>
  );
};

export default DesktopRegionsList;
