import { useMemo } from 'react';
import { getLocalizedMonthLabel } from 'utils/datesСalculation';

export function useGetLocalizedMonthLabels(moths) {
  return useMemo(() => {
    if (!moths) {
      return [];
    }
    return moths.map((el) => getLocalizedMonthLabel('en-gb', el.month));
  }, [moths]);
}
