import React from 'react';

import { T8y } from 'ui';

import style from './mainProductPrice.module.scss';

const MainProductPrice = ({ mainPrice, isNoFrom, isNoWrap }) => {
  return (
    <div className={style.priceWrapper}>
      {!!mainPrice && (
        <T8y variant="t2v1" bold noWrap={isNoWrap}>
          {isNoFrom ? '' : 'from'} £{mainPrice.toFixed(2)}{' '}
          <T8y as="span" noWrap variant="t4" color="wilkoGray">
            (exc VAT)
          </T8y>
        </T8y>
      )}
    </div>
  );
};

export default MainProductPrice;
