import React from 'react';
import clsx from 'clsx';

import { inputStyle, Row, Spinner, T8y } from 'ui';

import style from './checkoutInputField.module.scss';

const CheckoutInputField = ({
  title,
  errorText,
  innerRef,
  additionalClass,
  additionalInputClass,
  inputName,
  isRequiredMark,
  children,
  isLoading,
  inputWrapperRef,
  customInput,
  TitleIcon,
  ...rest
}) => {
  return (
    <Row
      direction="column"
      className={clsx(style.checkoutContactField, additionalClass)}
    >
      {title && (
        <T8y as="span" variant="t4" color="mainGray" mb className={style.title}>
          {title}
          {isRequiredMark ? '*' : ''}
          {TitleIcon && <TitleIcon />}
        </T8y>
      )}
      <div
        className={clsx(
          style.checkoutContactFieldInputWrapper,
          isLoading && style.loadingInput,
        )}
        ref={inputWrapperRef}
      >
        {customInput || (
          <input
            type="text"
            className={clsx(inputStyle, style.notCustomInput)}
            name={inputName}
            ref={innerRef}
            {...rest}
          />
        )}
        {isLoading && (
          <div className={style.spinnerWrapper}>
            <Spinner size={1} />
          </div>
        )}
      </div>
      {children}
      <T8y variant="t4" color="danger" as="p" className={style.error}>
        {errorText}
      </T8y>
    </Row>
  );
};

export default CheckoutInputField;
