import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useHandleCategoryMenu } from 'hooks';

import NestedCategoriesMenu from './NestedCategoriesMenu';

import { Row, T8y } from 'ui';

import { ReactComponent as ArrowBack } from 'assets/images/icons/arrowBack.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/carat-right.svg';

import style from '../header.module.scss';

const SubMenuMobile = ({ id, openId, setOpenId, close }) => {
  const handleGoToCategory = useCallback(() => {
    close();
    setOpenId(0);
  }, [close, setOpenId]);

  const { handleReturnToParentCategory, selectedCategory, goToCategory } =
    useHandleCategoryMenu(handleGoToCategory);

  const isThisTabOpened = openId === id;

  const handleBackClick = useCallback(
    (e) => {
      if (!selectedCategory) {
        setOpenId(0);
      }
      handleReturnToParentCategory(e);
    },
    [handleReturnToParentCategory, selectedCategory, setOpenId],
  );

  return (
    <div className={style.category}>
      <Row
        justify="stretch"
        align="center"
        className={clsx(
          style.name,
          style.border,
          openId > 0 && !isThisTabOpened && style.none,
        )}
        onClick={() => {
          setOpenId(isThisTabOpened && !selectedCategory ? 0 : 1);
        }}
        data-testid="mobile-categories-disclosure"
      >
        {isThisTabOpened ? (
          <div className={style.backRowWrapper} onClick={handleBackClick}>
            <Row align="center">
              <ArrowBack className={style.backArrow} />{' '}
              <T8y variant="t1" color="light" className={style.menuTitle}>
                Back
              </T8y>
            </Row>
            <div className={style.prevCategoryTextWrapper}>
              <T8y variant="t3" className={style.itemName}>
                {selectedCategory?.name || 'Select a skip'}
              </T8y>
            </div>
          </div>
        ) : (
          <>
            <T8y variant="t1" className={style.menuTitle}>
              Select a skip
            </T8y>
            <ArrowRight />
          </>
        )}
      </Row>
      {isThisTabOpened && <NestedCategoriesMenu goToCategory={goToCategory} />}
    </div>
  );
};

export default SubMenuMobile;
