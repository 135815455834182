import React, { useMemo } from 'react';
import { PATHS } from 'AppPaths';
import MultipleLinksMenuWrapper from 'layout/Header/MultipleLinksMenuWrapper';

const Company = ({ setIsSubMenuOpen }) => {
  const linksList = useMemo(() => [{ path: PATHS.FAQ, title: 'FAQ' }], []);

  return (
    <MultipleLinksMenuWrapper
      setIsSubMenuOpen={setIsSubMenuOpen}
      linksList={linksList}
    />
  );
};

export default Company;
