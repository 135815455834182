import React from 'react';
import { useStore } from 'effector-react';
import { products$ } from 'models/product';

import MenuItem from './MenuItem';

const NestedCategoriesMenu = ({ goToCategory }) => {
  const products = useStore(products$);

  return (
    <div>
      {products.map((category) => (
        <MenuItem
          category={category}
          onClick={() => goToCategory(category)}
          key={category.id}
        />
      ))}
    </div>
  );
};

export default NestedCategoriesMenu;
