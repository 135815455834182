import { useEffect } from 'react';
import {
  removeBreadCrumbs,
  setBreadCrumbs,
  setBreadCrumbsIsLoading,
} from 'models/breadCrumbs';

export const useBreadCrumbs = (breadCrumbs, isLoading = false) => {
  useEffect(() => {
    if (!isLoading) {
      setBreadCrumbs(breadCrumbs);
    }
    setBreadCrumbsIsLoading(isLoading);

    return removeBreadCrumbs;
  }, [breadCrumbs, isLoading]);
};
