import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { MOBILE_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { useItemInCart, useMatchMedia } from 'hooks';
import { isNewProductAdded$, setIsCartPopupVisible } from 'models/cart';

import MobileCalendarModal from 'features/checkout/MobileCalendarModal';
import ProductQuantityChanger from 'features/checkout/ProductQuantityChanger';

import DatePicker from '../DatePicker';

import { Row, Spinner, T8y } from 'ui';

import { ReactComponent as RemoveIcon } from 'assets/images/icons/cart/remove.svg';
import defaultImageThumb from 'assets/images/pictures/wilko/thumb-fallback.svg';

import style from './productListItem.module.scss';

const ProductListItem = ({
  itemInCart,
  slug,
  image,
  quantity,
  isLoading,
  indexItem,
  totalPrice,
  isProductDisabled,
  isLastItem,
  datePickerOpenId,
  setDatePickerOpenId,
  unitPrice,
}) => {
  const currProductRef = useRef();
  const isNewProductAdded = useStore(isNewProductAdded$);

  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);

  const {
    productTitle,
    onDateChangeCallback,
    productInCartCount,
    minQuantity,
    handleChangeCount,
    handleDelete,
  } = useItemInCart({
    itemInCart,
    quantity,
    isLoading,
    indexItem,
    isPopup: true,
  });

  const itemsTotal = unitPrice ? unitPrice * quantity : 0;

  //scroll to the last item in cart
  useEffect(() => {
    if (isNewProductAdded && isLastItem && currProductRef.current) {
      currProductRef.current.parentNode.scrollTop =
        currProductRef.current.offsetTop;
    }
  }, [isLastItem, isNewProductAdded]);

  const showDatePicker =
    datePickerOpenId && datePickerOpenId === itemInCart.product?.id;

  const handleChangeDates = useCallback(
    (dates) => {
      onDateChangeCallback(dates);
      setDatePickerOpenId(null);
    },
    [onDateChangeCallback, setDatePickerOpenId],
  );

  const daysText = useMemo(() => {
    const product = itemInCart.product;

    if (!product.min_period_days) {
      return '';
    }

    return `(Minimum period: ${product.min_period_days} ${
      product.min_period_days === 1 ? 'day' : 'days'
    })`;
  }, [itemInCart.product]);

  return (
    <Row
      className={clsx(
        style.productListItemWrapper,
        isLastItem && isNewProductAdded && style.isNewProductAdded,
      )}
      ref={currProductRef}
      noWrap
    >
      <div className={style.imageWrapper}>
        <img src={image || defaultImageThumb} alt={productTitle} />
      </div>
      <Row smDirection="column" className={style.productListItemInfoWrapper}>
        <Row direction="column" fullWidth>
          <Row
            align="start"
            direction="column"
            fullWidth
            className={style.centralInfoWrapper}
          >
            <div className={style.productTitleRow}>
              <T8y
                as={Link}
                to={PATHS.PRODUCT(slug)}
                onClick={() => {
                  setIsCartPopupVisible(false);
                }}
                variant="t3"
                linkOpposite
                className={style.itemTitle}
              >
                {productTitle}
              </T8y>
              <div
                onClick={isProductDisabled ? undefined : handleDelete}
                className={style.removeIconWrapper}
                role="button"
              >
                <RemoveIcon />
              </div>
            </div>

            <T8y variant="t3" className={style.priceWrapper}>
              {`£${(unitPrice || 0).toFixed(2)} per item`}
            </T8y>
          </Row>

          <T8y color="midGray" className={style.hiringPeriodText}>
            Hire Period
          </T8y>

          <Row align="center" className={style.datesWrapper}>
            <T8y variant="t3">{itemInCart.dates?.start}</T8y> -{' '}
            <T8y variant="t3">{itemInCart.dates?.end}</T8y>
            &nbsp;
            <T8y
              variant="t3"
              color={isProductDisabled ? 'gray' : 'primary'}
              linkOpposite
              onClick={() => {
                setDatePickerOpenId(itemInCart.product.id);
              }}
              className={style.changeButton}
            >
              Change...
            </T8y>
            {showDatePicker && isMobile && (
              <MobileCalendarModal
                cartItem={itemInCart}
                setIsCalendarModalOpen={() => setDatePickerOpenId(null)}
                handleDatesChange={handleChangeDates}
                daysText={daysText}
              />
            )}
            {showDatePicker && !isMobile && (
              <DatePicker
                cartItem={itemInCart}
                onChangeDates={handleChangeDates}
                onCancel={() => setDatePickerOpenId(null)}
              />
            )}
          </Row>
        </Row>

        <Row justify="stretch" align="center" fullWidth>
          <ProductQuantityChanger
            startCount={productInCartCount}
            maxQuantity={1000}
            minQuantity={minQuantity}
            changeCount={handleChangeCount}
            isLoading={isLoading}
            isProductDisabled={isProductDisabled}
            size={36}
          />
          <T8y variant="t3" bold className={style.priceWrapper}>
            {isLoading ? <Spinner size={1} /> : `£${itemsTotal.toFixed(2)}`}
          </T8y>
        </Row>
      </Row>
    </Row>
  );
};

export default ProductListItem;
