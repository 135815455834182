import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'AppPaths';

//TODO remove previouslySelectedCategory
export const useHandleCategoryMenu = (handleGoToCategory) => {
  const history = useHistory();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [previouslySelectedCategory, setPreviouslySelectedCategory] =
    useState(null);

  const handleChooseMainCategory = useCallback((item) => {
    setSelectedCategory(item);
    setPreviouslySelectedCategory(null);
  }, []);

  const handleReturnToParentCategory = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedCategory(previouslySelectedCategory);
      setPreviouslySelectedCategory(previouslySelectedCategory?.parent || null);
    },
    [previouslySelectedCategory],
  );

  const goToCategory = useCallback(
    () => {
      setSelectedCategory(null);
      setPreviouslySelectedCategory(null);

      if (handleGoToCategory) {
        handleGoToCategory();
      }

      history.push(PATHS.CATEGORY);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGoToCategory],
  );

  const handleChooseSubCategory = useCallback(
    (e, item) => {
      e.preventDefault();
      e.stopPropagation();

      if (!item.childs?.length) {
        goToCategory(item);
        return;
      }

      setPreviouslySelectedCategory(selectedCategory);
      setSelectedCategory(item);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCategory],
  );

  return useMemo(
    () => ({
      handleChooseMainCategory,
      handleReturnToParentCategory,
      handleChooseSubCategory,
      selectedCategory,
      selectedSubCategory,
      setSelectedSubCategory,
      setSelectedCategory,
      previouslySelectedCategory,
      goToCategory,
    }),
    [
      handleChooseMainCategory,
      handleChooseSubCategory,
      handleReturnToParentCategory,
      selectedCategory,
      selectedSubCategory,
      setSelectedSubCategory,
      previouslySelectedCategory,
      goToCategory,
    ],
  );
};
