import { useMemo } from 'react';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { enGB } from 'date-fns/locale';
import startOfWeek from 'date-fns/startOfWeek';

export function useGetLocalizedWeeksLabels(formatLabels = 'EEE') {
  return useMemo(() => {
    const firstDOW = startOfWeek(new Date(), { weekStartsOn: 1 });

    return Array.from(Array(7)).map((e, i) =>
      format(addDays(firstDOW, i), formatLabels, {
        locale: enGB,
      }),
    );
  }, [formatLabels]);
}
