import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { REACT_APP_POSTCODE_API_KEY } from 'consts';
import { sendMsgToSlack } from 'utils/sendMsgToSlack';

import {
  fullAddresses,
  shortAddresses,
} from 'ui/PostcoderInput/mockedAddresses';

export const useGetAddressesByPostcode = ({
  changeDeliveryPostcodeAddress,
  currPostCodeStr,
  setIsPostcodesListVisible,
  postCodeError,
  setEmptyAddress,
  setCurrPostCodeStr,
  setSearchRequestError,
  findNewAddressClickCallback,
  addressClickCallback,
  makePostcodeListVisible,
  autoScrollToList,
}) => {
  const [hits, setHits] = useState([]);
  const [filterPaths, setFilterPaths] = useState([]);
  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addressLevelClicked, setAddressLevelClicked] = useState(1);

  const isProdEnv = window.location.hostname !== 'localhost';

  const removeAllAddressData = useCallback(() => {
    setEmptyAddress();
    setCurrPostCodeStr('');
    setFilterPaths([]);
    setId(null);
  }, [setCurrPostCodeStr, setEmptyAddress]);

  useEffect(() => {
    if (addressLevelClicked !== 1) {
      setAddressLevelClicked(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currPostCodeStr]);

  const findPlaceByPostcode = useCallback(async () => {
    try {
      let data = {};

      if (isProdEnv) {
        data = await axios.get(
          `https://ws.postcoder.com/pcw/autocomplete/find?query=${currPostCodeStr}&country=uk&apikey=${REACT_APP_POSTCODE_API_KEY}&format=json&lines=2${
            filterPaths.length
              ? `&pathFilter=${filterPaths[filterPaths.length - 1]}`
              : ''
          }`,
        );
      } else {
        if (currPostCodeStr === 'RG2 0SA') {
          throw new Error('Invalid postcode');
        }

        data.data = shortAddresses.filter((el) =>
          el.postcode
            .replaceAll(' ', '')
            .toLowerCase()
            .includes(currPostCodeStr.replaceAll(' ', '').toLowerCase()),
        );
      }

      if (!data.data.length && filterPaths.length) {
        setFilterPaths([]);
      }

      setHits(data.data);

      if (autoScrollToList) {
        makePostcodeListVisible();
      } else {
        setIsPostcodesListVisible(true);
      }

      setSearchRequestError(null);
    } catch (e) {
      console.warn(e);
      setSearchRequestError('Something went wrong, try again later');
    } finally {
      setIsLoading(false);
    }
  }, [
    autoScrollToList,
    currPostCodeStr,
    filterPaths,
    isProdEnv,
    makePostcodeListVisible,
    setIsPostcodesListVisible,
    setSearchRequestError,
  ]);

  const findPlaceById = useCallback(async () => {
    try {
      let data = {};

      if (isProdEnv) {
        data = await axios.get(
          `https://ws.postcoder.com/pcw/autocomplete/retrieve/?country=uk&apikey=${REACT_APP_POSTCODE_API_KEY}&format=json&addtags=latitude,longitude,sortcode,postalcounty&lines=2${
            id ? `&id=${id}` : ''
          }`,
        );
      } else {
        data.data = [fullAddresses.find((el) => el.id === id)];
      }

      const currAddress = data.data[0];

      const firstAddressLine = currAddress.addressline1?.replace(',', '');
      const secondAddressLine = currAddress.addressline2?.replace(',', '');
      const country = 'United Kingdom';

      changeDeliveryPostcodeAddress({
        postcoderAddressId: id,
        name: `${firstAddressLine}${
          secondAddressLine ? `, ${secondAddressLine}` : ''
        }${currAddress.posttown ? `, ${currAddress.posttown}` : ''}, ${
          currAddress.postcode
        }, ${country}`,
        postcode: currAddress.postcode,
        lat: currAddress.latitude,
        lng: currAddress.longitude,
        addressLine1: firstAddressLine,
        addressLine2: secondAddressLine,
        town: currAddress.posttown,
        country,
      });

      setAddressLevelClicked(1);
      setSearchRequestError(null);
    } catch (e) {
      console.warn(e);
      setSearchRequestError('Something went wrong, try again later');

      if (e.response.status === 429 || e.response.status === 403) {
        sendMsgToSlack(`TA POSTCODER error: ${e.response.data}`);
      }
    } finally {
      setIsLoading(false);
    }
  }, [changeDeliveryPostcodeAddress, id, isProdEnv, setSearchRequestError]);

  const handleAddressClick = useCallback(
    (el) => {
      if (addressClickCallback) {
        addressClickCallback(addressLevelClicked);
      }

      setAddressLevelClicked((currValue) => currValue + 1);

      if (!el.count || el.count === 1) {
        setFilterPaths([]);
        setHits([]);
        setId(el.id);
        return;
      }

      setFilterPaths([...filterPaths, el.id]);
    },
    [addressClickCallback, addressLevelClicked, filterPaths],
  );

  //handle postcode changes
  useEffect(() => {
    if (!postCodeError && currPostCodeStr) {
      setIsLoading(true);
      findPlaceByPostcode();
    } else {
      setHits([]);
      setIsPostcodesListVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currPostCodeStr]);

  //handle "back to results" functionality
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      findPlaceById(id);
      return;
    }

    if (currPostCodeStr && !postCodeError) {
      setIsLoading(true);
      findPlaceByPostcode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPaths]);

  const handleBackToResultsClick = useCallback(() => {
    const newFilters = [...filterPaths];
    newFilters.splice(filterPaths.length - 1, 1);
    setFilterPaths(newFilters);
  }, [filterPaths]);

  const handleFindNewAddressClick = useCallback(() => {
    removeAllAddressData();
    setIsPostcodesListVisible(false);

    if (findNewAddressClickCallback) {
      findNewAddressClickCallback();
    }
  }, [
    findNewAddressClickCallback,
    removeAllAddressData,
    setIsPostcodesListVisible,
  ]);

  return useMemo(
    () => ({
      handleFindNewAddressClick,
      handleBackToResultsClick,
      handleAddressClick,
      hits,
      isLoading,
      filterPaths,
    }),
    [
      handleAddressClick,
      handleBackToResultsClick,
      handleFindNewAddressClick,
      hits,
      isLoading,
      filterPaths,
    ],
  );
};
