import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { T8y } from 'ui';

import style from './menuItem.module.scss';

const MenuItem = ({
  title,
  href,
  additionalClassName,
  setIsSubMenuOpen,
  isSubMenuOpen,
  children,
  isSingleLink,
  isExternalLink,
  isNoCapitalize,
  onClickCallback,
}) => {
  const handleSetIsSubMenuOpen = useCallback(
    (value) => {
      if (setIsSubMenuOpen) {
        setIsSubMenuOpen(value);
      }
    },
    [setIsSubMenuOpen],
  );

  if (isExternalLink) {
    return (
      <T8y
        as="a"
        href={href || '#'}
        color="light"
        className={clsx(style.single, style.linkHover)}
        onClick={onClickCallback}
        noWrap
        rel="noopener noreferrer"
      >
        {title}
      </T8y>
    );
  }

  return (
    <div
      className={clsx(
        isSubMenuOpen && style.linkHover,
        isSingleLink ? style.single : style.linkWrapper,
        !isSingleLink && style.disclosure,
        additionalClassName,
      )}
      onMouseOver={() => handleSetIsSubMenuOpen(true)}
      onMouseLeave={() => handleSetIsSubMenuOpen(false)}
      onClick={() => handleSetIsSubMenuOpen(!isSubMenuOpen)}
    >
      {href ? (
        <Link
          to={href}
          className={clsx(style.link, !isNoCapitalize && style.capitalized)}
          onClick={onClickCallback}
        >
          {title}
        </Link>
      ) : (
        <span
          className={clsx(style.link, !isNoCapitalize && style.capitalized)}
        >
          {title}
        </span>
      )}
      {children}
    </div>
  );
};

export default MenuItem;
