import React, { useEffect } from 'react';
import { forceCheck } from 'react-lazyload';
import clsx from 'clsx';

import { BigProductCard, ProductCard } from 'ui';

import style from './cardsGrid.module.scss';

export function CardsGrid({ cards, isBigCard, cardsWithoutBorder, className }) {
  useEffect(() => {
    forceCheck();
  }, [cards]);

  return (
    <div
      className={clsx(
        isBigCard ? undefined : style.productCardsWrapper,
        className,
      )}
    >
      {cards.map((card, i) => {
        return isBigCard ? (
          <BigProductCard key={i} testId={`product-${i}`} product={card} />
        ) : (
          <ProductCard
            key={i}
            testId={`product-${i}`}
            product={card}
            withoutBorder={cardsWithoutBorder}
          />
        );
      })}
    </div>
  );
}
