import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { useTotalPriceCalculating } from 'hooks';
import {
  cart$,
  cartAllItemsLength$,
  cartFormattedData$,
  latestTotalPriceInfo$,
  setIsCartPopupVisible,
} from 'models/cart';

import CheckoutBtn from 'features/checkout/CheckoutBtn';

import ProductList from './ProductsList';

import { Row, Spinner, T8y } from 'ui';

import { ReactComponent as CloseIcon } from 'assets/images/icons/closeCart.svg';

import style from './cartPopup.module.scss';

const CartPopup = () => {
  const ref = useRef();
  const { latestTotalPrice, isLoadingLatestTotalPrice } = useStore(
    latestTotalPriceInfo$,
  );
  const cartAllItemsLength = useStore(cartAllItemsLength$);
  const cartFormattedData = useStore(cartFormattedData$);
  const cart = useStore(cart$);

  const history = useHistory();

  useTotalPriceCalculating({
    cartFormattedData,
    postcode: cart[0]?.postcode,
  });

  const handleBtnClick = useCallback(() => {
    setIsCartPopupVisible(false);
    history.push(PATHS.CHECKOUT);
  }, [history]);

  return (
    <div className={style.cartReservationPopover}>
      <div className={style.cartReservationWrapper}>
        <Row justify="stretch" align="center" className={style.popoverHeader}>
          <T8y variant="t1v1" bold>
            Your basket ({cartAllItemsLength})
          </T8y>
          <button
            type="button"
            className={style.closeWrapper}
            onClick={() => {
              setIsCartPopupVisible(false);
            }}
            data-testid="reservation-popup-close-btn"
          >
            <CloseIcon />
          </button>
        </Row>
        <div ref={ref} className={style.cartReservationList}>
          <ProductList />
        </div>
        <div className={style.popoverFooter}>
          <Row
            justify="stretch"
            data-testid="total"
            className={style.totalPriceWrapper}
          >
            <T8y variant="t2v1" className={style.totalPriceText} as="span" bold>
              Subtotal:
            </T8y>
            {isLoadingLatestTotalPrice ? (
              <Spinner size={1} className={style.totalPriceLoadingSpinner} />
            ) : (
              <T8y
                variant="t2v1"
                color="primary"
                className={style.totalPriceText}
                bold
                as="span"
                noWrap
              >
                £{latestTotalPrice.subtotal?.toFixed(2)}
              </T8y>
            )}
          </Row>
          <Row direction="column" fullWidth>
            <CheckoutBtn
              onClick={handleBtnClick}
              text="Checkout"
              additionalClass={clsx(style.cartActionBtn, style.checkoutBtn)}
              isSelected
            />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CartPopup;
