export function excludeSpaceSignFromUrl(str) {
  return str.replace(/%20/g, ' ');
}

export function getUrlSearchParams(search) {
  return search
    .slice(1)
    .split('&')
    .reduce((acc, s) => {
      const [k, v] = s.split('=');
      return Object.assign(acc, { [k]: v });
    }, {});
}

export function checkUrlSearchParams(params, keys) {
  const utmParams = Object.entries(params).reduce((acc, el) => {
    const key = el[0];
    const value = el[1];

    if (!keys.includes(key)) {
      return acc;
    }

    return { ...acc, [key]: excludeSpaceSignFromUrl(value) };
  }, {});

  if (Object.keys(utmParams).length) {
    return utmParams;
  }
  return null;
}
