import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { REACT_APP_PUBLIC_URL } from 'consts';
import { useStore } from 'effector-react';
import { createFakeOrderFx } from 'models/cart';
import * as CR from 'models/cart';

import { useGetCommonCheckoutData } from './useGetCommonCheckoutData';

export const useGetFakeOrderLink = (cancelToken) => {
  const details = useStore(CR.details$);
  const checkoutComments = useStore(CR.checkoutComments$);
  const cart = useStore(CR.cart$);

  const getFormattedCheckoutData = useGetCommonCheckoutData({ details });

  const checkoutData = getFormattedCheckoutData();

  return useCallback(async () => {
    try {
      const res = await createFakeOrderFx({
        checkoutData: {
          ...checkoutData,
          comment: checkoutComments,
          postcode: cart[0]?.postcode,
          address: cart[0]?.fullAddress,
        },
        config: cancelToken ? { cancelToken } : undefined,
      });

      return `${REACT_APP_PUBLIC_URL}${PATHS._RESERVATION}?id=${res.slug}&utm_source=reservation`;
    } catch (e) {
      if (e.message !== 'manual cancel') {
        toast.error('Something went wrong');
      }
    }
  }, [cancelToken, cart, checkoutComments, checkoutData]);
};
