export const PATHS = Object.freeze({
  ROOT: '/',
  _CATEGORY: 'skip-hire',
  _RESERVATION: 'reservation',
  _CHECKOUT: 'checkout',
  _SUCCESS: 'success',
  _FAQ: 'frequently-asked-questions',
  _COOKIE_POLICY: 'cookie-policy',
  _TERMS_AND_CONDITIONS: 'terms-and-conditions',
  _PRIVACY_POLICY: 'privacy-policy',

  get PRODUCT_TEMPLATE() {
    return `${this.ROOT}${this._CATEGORY}/:productSlug/`;
  },

  get CATEGORY() {
    return `${this.ROOT}${this._CATEGORY}/`;
  },
  PRODUCT(productSlug) {
    return `${this.ROOT}${this._CATEGORY}/${productSlug}/`;
  },
  get RESERVATION() {
    return `${this.ROOT}${this._RESERVATION}/`;
  },
  get CHECKOUT() {
    return `${this.ROOT}${this._RESERVATION}/${this._CHECKOUT}/`;
  },
  get SUCCESS() {
    return `${this.ROOT}${this._SUCCESS}/`;
  },
  get FAQ() {
    return `${this.ROOT}${this._FAQ}/`;
  },
  get COOKIE_POLICY() {
    return `${this.ROOT}${this._COOKIE_POLICY}/`;
  },
  get TERMS_AND_CONDITIONS() {
    return `${this.ROOT}${this._TERMS_AND_CONDITIONS}/`;
  },
  get PRIVACY_POLICY() {
    return `${this.ROOT}${this._PRIVACY_POLICY}/`;
  },
});
