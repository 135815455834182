import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';

import { Row, T8y } from 'ui';

import { ReactComponent as ArrowRight } from 'assets/images/icons/carat-right.svg';

import style from '../../../header.module.scss';

const MenuItem = ({ category, onClick, additionalClassName, isCategory }) => {
  return isCategory ? (
    <Row
      justify="stretch"
      align="center"
      className={clsx(style.name, style.border, additionalClassName)}
      onClick={onClick}
    >
      <T8y
        variant="t1"
        color="light"
        className={clsx(
          style.menuTitle,
          isCategory && style.menuTitleWithArrow,
        )}
      >
        {category.name}
      </T8y>
      <ArrowRight />
    </Row>
  ) : (
    <Link
      to={PATHS.PRODUCT(category.slug)}
      className={clsx(
        style.name,
        style.border,
        style.menuTitle,
        style.menuLink,
        additionalClassName,
      )}
      onClick={onClick}
    >
      {category.name}
    </Link>
  );
};

export default MenuItem;
