import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { LAPTOP_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { useMatchMedia } from 'hooks';
import { breadCrumbs$, breadCrumbsIsLoading$ } from 'models/breadCrumbs';
import { scrollTo } from 'utils/scrollToTop';

import { Row, T8y } from 'ui';

import { ReactComponent as Arrow } from 'assets/images/icons/arrowRight.svg';

import style from './breadCrumbs.module.scss';

export const BreadCrumbs = () => {
  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);

  const lastElementRef = useRef();

  const breadCrumbs = useStore(breadCrumbs$);
  const breadCrumbsIsLoading = useStore(breadCrumbsIsLoading$);

  const breadCrumbsList = useMemo(
    () => (breadCrumbs && Object.values(breadCrumbs)) || [],
    [breadCrumbs],
  );

  useEffect(() => {
    if (isLaptop && lastElementRef?.current) {
      scrollTo(lastElementRef.current, 24);
    }
  }, [isLaptop, breadCrumbsList]);

  const isProductMobile = useMemo(() => {
    return breadCrumbsList[0]?.isProductMobile;
  }, [breadCrumbsList]);

  if (breadCrumbsIsLoading) {
    return <div className={clsx(style.breadCrumbsSmall, style.emptyScroll)} />;
  }

  if (!breadCrumbs) {
    return null;
  }

  return (
    <div
      className={clsx(
        style.breadcrumbsWrapper,
        !!breadCrumbs.product && style.productBreadcrumbsWrapper,
      )}
    >
      <Row
        align="center"
        className={clsx(
          style.breadcrumbsLinksWrapper,
          !!breadCrumbs.product
            ? style.productContainer
            : style.limitedContainer,
          isLaptop ? style.breadCrumbsSmall : style.breadCrumbs,
          !!breadCrumbs.product && style.lastProductBreadcrumbs,
        )}
      >
        {breadCrumbsList.map((el, index) => (
          <Row
            align="center"
            noWrap
            className={style.linkContent}
            ref={
              index === breadCrumbsList.length - 1 ? lastElementRef : undefined
            }
            key={index}
          >
            {!isProductMobile && index === breadCrumbsList.length - 1 ? (
              <T8y
                as="span"
                variant="t1"
                mdVariant="t2v1"
                className={clsx(style.breadcrumbLink, style.notClickableLink)}
              >
                {el.name}
              </T8y>
            ) : (
              <T8y
                as={el.isExternalLink ? 'a' : Link}
                to={{
                  pathname: el.link,
                  presetType: el.presetType,
                }}
                href={el.link}
                className={style.breadcrumbLink}
                variant="t1"
                mdVariant="t2v1"
              >
                {el.name}
              </T8y>
            )}
            {index !== breadCrumbsList.length - 1 && (
              <div className={style.arrowWrapper}>
                <Arrow />
              </div>
            )}
          </Row>
        ))}
      </Row>
      {!!breadCrumbs.product && (
        <div className={style.fakeCostSummaryContainer} />
      )}
    </div>
  );
};
