import { useEffect, useMemo } from 'react';
import { MOBILE_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { useMatchMedia } from 'hooks';
import { isCartPopupVisible$ } from 'models/cart';

export const useHideChats = ({ isAlwaysHide, isHideOnMobile }) => {
  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);

  const isCartPopupVisible = useStore(isCartPopupVisible$);

  const isHide = useMemo(() => {
    if (isAlwaysHide || (isCartPopupVisible && isMobile)) {
      return true;
    }

    return isHideOnMobile && isMobile;
  }, [isAlwaysHide, isCartPopupVisible, isHideOnMobile, isMobile]);

  useEffect(() => {
    if (!isHide) {
      return;
    }

    const whatsAppIcon = document.querySelector('a[href^="https://wa.me/"]');

    if (whatsAppIcon) {
      whatsAppIcon.setAttribute('style', 'display: none');
      return () => whatsAppIcon.removeAttribute('style');
    }
  }, [isHide]);
};
