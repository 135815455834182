import React from 'react';
import { Button as RButton } from 'reakit';
import clsx from 'clsx';

import { Spinner } from 'ui';
import { Row } from 'ui/Grid/Row';

import style from './button.module.scss';

export const Button = React.forwardRef(
  (
    {
      children,
      className,
      loading,
      inverse,
      transparent,
      dark,
      small,
      inline,
      noWrap,
      prefixIcon: prefixIconComponent,
      postfixIcon: postfixIconComponent,
      prefix: prefixComponent,
      postfix: postfixComponent,
      as = 'button',
      isNoStretch,
      ...rest
    },
    ref,
  ) => {
    return (
      <RButton
        disabled={loading}
        as={as}
        className={clsx(style.button, className, {
          [style.buttonLoading]: loading,
          [style.buttoninverse]: inverse,
          [style.buttontransparent]: transparent,
          [style.buttondark]: dark,
          [style.buttonsmall]: small,
          [style.buttoninline]: inline,
          [style.buttonnoWrap]: noWrap,
          [style.buttonprefix]: prefixComponent,
          [style.buttonpostfix]: postfixComponent,
        })}
        ref={ref}
        {...rest}
      >
        {prefixComponent && (
          <Row
            as="span"
            align="center"
            justify="center"
            className={style.prefix}
          >
            {prefixComponent}
          </Row>
        )}
        <Row
          align="center"
          justify="center"
          className={isNoStretch ? undefined : style.buttonContentStretch}
        >
          {prefixIconComponent && (
            <div className={style.prefixIcon}>{prefixIconComponent}</div>
          )}
          {loading ? <Spinner /> : children}
          {postfixIconComponent && (
            <div className={style.postfixIcon}>{postfixIconComponent}</div>
          )}
        </Row>

        {postfixComponent && (
          <Row
            as="span"
            align="center"
            justify="center"
            className={style.postfix}
          >
            {postfixComponent}
          </Row>
        )}
      </RButton>
    );
  },
);
