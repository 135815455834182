import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_COUNTRY_BASE_URL}/api`,
  defaultInterceptors: true,
});

export const PRODUCTS = {
  getAll() {
    return instance.get('products');
  },
  getProduct(slug) {
    return instance.get(`products/${slug}`);
  },
};

export const CART = {
  getTotalPrice(params, signal) {
    return instance.post(`/checkout/prepare`, params, { signal });
  },
};

export const CHECKOUT = {
  go(params) {
    return instance.post('/checkout/proceed', params);
  },
  pay(orderId) {
    return instance.get(`checkout/${orderId}/pay`);
  },
};

export const PROMO = {
  checkPromoCode(params) {
    return instance.post('/promocodes/validate', params);
  },
};

export const CRM = {
  sendTask(params) {
    return instance.post('/crm/task', params);
  },
};

export const ENQUIRY = {
  sendEnquiry(params) {
    return instance.post('/events/enquiry', params);
  },
};

export const QUERIES = {
  getFakeOrder(id) {
    return instance.get(`/cart/${id}`);
  },
  createFakeOrder(params, config) {
    return instance.post('/cart', params, config);
  },
};
