import React, { useState } from 'react';
import { Group } from 'reakit';

import Catalog from '../Menu/Catalog';
import Company from '../Menu/Company';
import MenuItem from '../Menu/MenuItem';

import { Row } from 'ui';

import style from './nav.module.scss';

const Nav = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  return (
    <Group as={Row} align="center" noWrap mdHide ml3>
      <MenuItem
        setIsSubMenuOpen={setIsSubMenuOpen}
        isSubMenuOpen={isSubMenuOpen}
        additionalClassName={style.categoriesLink}
        title="Select a skip"
        isNoCapitalize
      >
        {isSubMenuOpen && <Catalog setIsSubMenuOpen={setIsSubMenuOpen} />}
      </MenuItem>
      <MenuItem
        isSubMenuOpen={isSubMenuOpen}
        setIsSubMenuOpen={setIsSubMenuOpen}
        title="Information"
      >
        {isSubMenuOpen && <Company setIsSubMenuOpen={setIsSubMenuOpen} />}
      </MenuItem>
      <MenuItem
        title="Back to wilko.com"
        isSingleLink
        isExternalLink
        href="https://www.wilko.com"
      />
    </Group>
  );
};

export default Nav;
