import { T8y } from 'ui';

import heroImage from 'assets/images/pictures/hero.jpg';

import style from './mobileHeroBanner.module.scss';

export const MobileHeroBanner = () => {
  return (
    <div className={style.bannerWrapper}>
      <div className={style.bannerContainer}>
        <div className={style.imageWrapper}>
          <img src={heroImage} className={style.bannerImage} />
        </div>

        <div className={style.bannerContent}>
          <T8y
            as="h1"
            color="light"
            center
            bold
            className={style.bannerContentTitle}
          >
            Great value skip hire with hassle-free booking!
          </T8y>
        </div>
      </div>
    </div>
  );
};
