import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import { persist } from 'effector-storage/local';

import { PRODUCTS } from 'api';

export const getAllProductsFx = createEffect(async (id) => {
  const { data } = await PRODUCTS.getAll(id);
  return data?.data
    ? data.data.map((el) => ({
        ...el,
        details: [
          `${el.weightCapacity} ton weight capacity`,
          `ideal for ${el.idealFor}`,
          `${el.binBags} bin bags`,
        ],
      }))
    : [];
});

export const productsGate = createGate('products');

export const isProductsLoaded$ = createStore(false).on(
  getAllProductsFx.finally,
  () => true,
);

export const getCurrProduct = createEvent();
export const setValidPostcode = createEvent();
export const setPermit = createEvent();

export const getCurrProductFx = createEffect(async (id) => {
  const { data } = await PRODUCTS.getProduct(id);

  return data.data;
});

export const products$ = restore(getAllProductsFx, []);

export const clearProductData = createEvent();

export const currProduct$ = restore(getCurrProductFx.doneData, {}).on(
  clearProductData,
  (_) => null,
);

export const address$ = createStore(null).on(
  setValidPostcode,
  (_, p) => p?.address || null,
);

export const validPostcode$ = createStore(null).on(
  setValidPostcode,
  (_, p) => p?.postcode || null,
);

export const permit$ = createStore(null)
  .on(setPermit, (_, p) => p)
  .on(validPostcode$.updates, (_, p) => {
    if (!p) {
      return null;
    }
  });

forward({
  from: productsGate.open,
  to: getAllProductsFx,
});

forward({
  from: getCurrProduct,
  to: getCurrProductFx,
});

persist({ store: validPostcode$, key: 'validPostcode' });
persist({ store: address$, key: 'address' });
persist({ store: permit$, key: 'permit' });

export const setFixedReservationBannerVisible = createEvent();

export const isFixedReservationBannerVisible$ = createStore(false).on(
  setFixedReservationBannerVisible,
  (_, p) => p,
);
