import { DEFAULT_PHONE_COUNTRY } from 'consts';
import { createEvent, createStore } from 'effector';
import { persist } from 'effector-storage/local';

export const setIsMenuOpen = createEvent();
export const setPhoneMaskCountry = createEvent();
export const setIsShowMessageIcon = createEvent();

export const isMenuOpen$ = createStore(false).on(setIsMenuOpen, (s, p) => p);

export const phoneMaskCountry$ = createStore(DEFAULT_PHONE_COUNTRY || null).on(
  setPhoneMaskCountry,
  (_, p) => p,
);

export const isShowMessageIcon$ = createStore(false).on(
  setIsShowMessageIcon,
  (_, p) => p,
);

persist({ store: phoneMaskCountry$, key: 'phoneMaskCountry' });
