import React from 'react';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { cartAllItemsLength$ } from 'models/cart';

import { ReactComponent as CartIcon } from 'assets/images/icons/basket.svg';

import style from './cartSign.module.scss';

const CartSing = () => {
  const cartAllItemsLength = useStore(cartAllItemsLength$);

  return (
    <>
      {!!cartAllItemsLength && (
        <div className={clsx(style.circle, style.circleWithNoPhone)}>
          {cartAllItemsLength}
        </div>
      )}
      <CartIcon className={style.cartIcon} />
    </>
  );
};

export default CartSing;
