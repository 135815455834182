import { useCallback, useMemo, useState } from 'react';
import { MOBILE_SCREEN } from 'consts';
import { useMatchMedia, useOnClickOutside } from 'hooks';

export const useManageHitsListVisibility = ({
  inputRef,
  listRef,
  excludeId,
}) => {
  const [isListVisible, setIsListVisible] = useState(false);

  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);
  const offsetFromTop = 100;

  useOnClickOutside(
    listRef,
    isMobile
      ? undefined
      : () => {
          setIsListVisible(false);
        },
    excludeId,
  );

  const scrollToList = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    window.scrollTo({
      behavior: 'smooth',
      top:
        inputRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        offsetFromTop -
        100,
    });
  }, [inputRef, offsetFromTop]);

  const makeListVisible = useCallback(() => {
    if (!isListVisible) {
      setIsListVisible(true);
      scrollToList();
    }
  }, [isListVisible, scrollToList]);

  return useMemo(
    () => ({
      makeListVisible,
      setIsListVisible,
      isListVisible,
    }),
    [makeListVisible, isListVisible, setIsListVisible],
  );
};
