import { useEffect } from 'react';
import { setUTMData } from 'models/UTMData';
import { getTimestampsDifference } from 'utils/datesСalculation';
import { checkUrlSearchParams, getUrlSearchParams } from 'utils/formatUrl';

const NEEDED_UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_keyword',
];

export const useSetUTM = () => {
  const fillUTMSession = () => {
    const UTMDataSession = sessionStorage.getItem('UTM');
    const UTMData = UTMDataSession ? JSON.parse(UTMDataSession) : null;
    const sessionTimestamp = sessionStorage.getItem('UTM_timestamp');
    const currentLocation = window.location;
    const params = getUrlSearchParams(currentLocation.search);

    const utmFromUrl = checkUrlSearchParams(params, NEEDED_UTM_KEYS);
    const utmFromSession = checkUrlSearchParams(UTMData || {}, NEEDED_UTM_KEYS);

    //if no utm in url and no utm in session
    if (!utmFromUrl && !utmFromSession && !UTMData?.utm_landing_page) {
      setUTMData({
        utm_landing_page: currentLocation.href,
      });
      return;
    }

    //if we have utm in url or our old utm doesn't have timestamp set
    if (
      !!utmFromUrl ||
      (!sessionTimestamp &&
        utmFromSession &&
        Object.keys(utmFromSession).length > 1)
    ) {
      setUTMData({
        utm_landing_page: currentLocation.href,
        ...(utmFromUrl && { ...utmFromUrl }),
      });
      sessionStorage.setItem('UTM_timestamp', new Date().toISOString());
      return;
    }

    checkUTMTimestamp();
  };

  const checkUTMTimestamp = () => {
    const UTMDataSession = sessionStorage.getItem('UTM');
    const UTMData = UTMDataSession ? JSON.parse(UTMDataSession) : null;
    const sessionTimestamp = sessionStorage.getItem('UTM_timestamp');

    //if we have utm timestamp we check if it's expired
    if (sessionTimestamp) {
      const currTimestamp = new Date().toISOString();
      const timestampsDifference = getTimestampsDifference(
        sessionTimestamp,
        currTimestamp,
      );

      if (timestampsDifference > 1440) {
        setUTMData({
          utm_landing_page: UTMData?.utm_landing_page || window.location.href,
        });
        sessionStorage.setItem('UTM_timestamp', '');
      }
    }
  };

  useEffect(() => {
    fillUTMSession();

    //clear session after 24 hours
    const utmCheckingInterval = setInterval(function () {
      checkUTMTimestamp();
    }, 300000);

    return () => {
      clearInterval(utmCheckingInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
