export const sendGAEvent = (event, products, value) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...(products?.length && {
      ecommerce: {
        currencyCode: 'GBP',
        items: products,
        ...(value && { ...value }),
      },
    }),
  });
};
