import React from 'react';

import { T8y } from 'ui';

import { ReactComponent as CallIcon } from 'assets/images/icons/phoneWhite.svg';

import style from '../../header.module.scss';

const CallSign = () => {
  return (
    <T8y
      className={style.phoneIconLink}
      color="light"
      as="a"
      href="tel:+447441350385"
      noWrap
      bold
    >
      <CallIcon />
      <span className={style.hiddenNumber}>+44 7441 350385</span>
    </T8y>
  );
};

export default CallSign;
