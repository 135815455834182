import { useRef } from 'react';
import { useStore } from 'effector-react';
import CommonHeader from 'layout/Header/CommonHeader';
import { isMenuOpen$ } from 'models/common';

import style from './header.module.scss';

export const Header = () => {
  const headerRef = useRef(null);
  const actualHeaderRef = useRef(null);

  const isMenuOpen = useStore(isMenuOpen$);

  return (
    <header className={style.headerWrapper} ref={headerRef}>
      <div
        ref={actualHeaderRef}
        id="static-header"
        className={style.headerContent}
      >
        <CommonHeader isMenuOpen={isMenuOpen} />
      </div>
    </header>
  );
};
