import { Helmet } from 'react-helmet';

export const Meta = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>

      {/* Google / Search Engine Tags */}
      <meta itemProp="name" content={title} />

      {/* Facebook Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:title" content={title} />
    </Helmet>
  );
};
