import React from 'react';
import { SMALL_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { useMatchMedia } from 'hooks';
import { products$ } from 'models/product';
import { mainPagePopularSlider } from 'utils/sliderSettings';

import { CardsGrid } from 'features/CardsGrid/CardsGrid';

import MainContentBlock from '../MainContentBlock';

import { ProductCard, Slider } from 'ui';

import style from './hireSkip.module.scss';

export const HireSkip = () => {
  const products = useStore(products$);

  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  return (
    <MainContentBlock
      title="Hire your skip today"
      wrapperClassName={style.contentBlockWrapper}
    >
      {isSmallScreen ? (
        <CardsGrid
          cards={products}
          cardsWithoutBorder
          className={style.cardsGridWrapper}
        />
      ) : (
        <Slider slidesToShow={4} {...mainPagePopularSlider}>
          {products.map((el, i) => (
            <div key={i} className={style.productCardWrapper}>
              <ProductCard product={el} withoutBorder />
            </div>
          ))}
        </Slider>
      )}
    </MainContentBlock>
  );
};
