import { END_DATE, START_DATE } from '@datepicker-react/hooks';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
  restore,
  sample,
} from 'effector';
import { persist } from 'effector-storage/local';
import { getFormattedProductsForTotalPriceRequest } from 'utils/formatProducts';
import { getObject } from 'utils/localStorage';

import { QUERIES } from 'api';

export const changeDatesRange = createEvent();
export const changeFocusedPickerDay = createEvent();

export const setTotalPrice = createEvent();
export const setTotalPriceLoading = createEvent();

export const latestTotalPriceInfo$ = createStore({
  latestTotalPrice: {},
  isLoadingLatestTotalPrice: true,
})
  .on(setTotalPrice, (state, props) => {
    return { latestTotalPrice: { ...props }, isLoadingLatestTotalPrice: false };
  })
  .on(setTotalPriceLoading, (state, props) => {
    return {
      latestTotalPrice: { ...state.latestTotalPrice },
      isLoadingLatestTotalPrice: true,
    };
  });

//CART ITEMS
export const addItemToCart = createEvent();
export const deleteItemFromCart = createEvent();
export const editItemInCart = createEvent();
export const resetCart = createEvent();

export const cart$ = createStore([])
  .on(addItemToCart, (_, newItem) => {
    return [newItem];
  })
  .on(editItemInCart, (s, p) => {
    return s.map((item) => (item.id === p.id ? { ...item, ...p } : item));
  })
  .on(deleteItemFromCart, (s, p) => s.filter(({ id }) => id !== p))
  .on(resetCart, (s, p) => []);

persist({ store: cart$, key: 'cart' });

//CART ATTRIBUTES FOR REQUEST
export const cartFormattedData$ = combine(cart$, (cart) => {
  if (!cart || !cart.length) {
    return [];
  }

  return cart.map((el) => getFormattedProductsForTotalPriceRequest(el));
});

export const focusedInput$ = createStore(START_DATE)
  .on(changeDatesRange, (s, { focusedInput, startDate, endDate }) => {
    if (startDate) return END_DATE;
    if (endDate) return START_DATE;
  })
  .on(changeFocusedPickerDay, (s, p) => {
    if (p === 'start') return START_DATE;
    if (p === 'end') return END_DATE;
  });

export const picker_state$ = combine(focusedInput$, (focusedInput) => {
  return { focusedInput };
});

export const createFakeOrderFx = createEffect({
  handler: async ({ checkoutData, config }) => {
    const { data } = await QUERIES.createFakeOrder(checkoutData);
    return data.data;
  },
});

export const getFakeOrderFx = createEffect({
  handler: async (id) => {
    const { data } = await QUERIES.getFakeOrder(id);
    return data.data;
  },
});

export const changeDetailsPaymentMethod = createEvent();
export const changeDetailsUserData = createEvent();
export const clearDetailsStorage = createEvent();
export const changeDetails = createEvent();

export const changeAppliedPromoCode = createEvent();
export const appliedPromoCode$ = restore(changeAppliedPromoCode, null);
export const changePromoCodeMaxDiscount = createEvent();
export const promoCodeMaxDiscount$ = restore(changePromoCodeMaxDiscount, null);

export const changePromoCode = createEvent();

const promoCodeLocalStorage = getObject('promoCode') || null;

export const promoCode$ = createStore(promoCodeLocalStorage).on(
  changePromoCode,
  (s, p) => p,
);

persist({ store: promoCode$, key: 'promoCode' });

const defaultDetails = {
  id: null,
  checkoutAs: null,
  email: null,
  name: null,
  phone: null,
  paymentMethod: null,
  type: null,
  company_name: null,
  company_id: null,
  insurance: null,
};

const detailsLocalStorage = getObject('details') || {
  ...defaultDetails,
};

export const details$ = createStore({
  ...detailsLocalStorage,
})
  .on(changeDetails, (s, p) => {
    return { ...s, ...p };
  })
  .on(changeDetailsPaymentMethod, (s, p) => {
    return { ...s, paymentMethod: p };
  })
  .on(changeDetailsUserData, (s, p) => {
    return { ...s, ...p };
  })
  .on(clearDetailsStorage, (_) => {
    return { ...defaultDetails };
  });

persist({ store: details$, key: 'details' });

export const changeInputFocused = createEvent();

export const changeCheckoutComments = createEvent();
const upperStoresUpdate = merge([changeDetailsUserData]);

export const checkoutComments$ = createStore(null)
  .on(changeCheckoutComments, (_, p) => {
    if (!p) {
      return p;
    }

    return p.trim();
  })
  .on(upperStoresUpdate, (_) => null);

persist({ store: checkoutComments$, key: 'checkoutComments' });

export const changeCheckoutPurchaseField = createEvent();

export const checkoutPurchaseField$ = createStore(null)
  .on(changeCheckoutPurchaseField, (_, p) => {
    if (!p) {
      return p;
    }

    return p.trim();
  })
  .on(upperStoresUpdate, (_) => null);

persist({ store: checkoutPurchaseField$, key: 'checkoutPurchaseField' });

const turnOffIsNewProductAddedDebouncedFx = createEffect().use(
  () =>
    new Promise((resolve) => {
      setTimeout(resolve, 3000);
    }),
);

//CART LENGTH
export const cartLength$ = cart$.map((currStoreValue) => {
  return currStoreValue?.length;
});

export const cartAllItemsLength$ = cart$.map((cart) => cart.length);

export const isNewProductAdded$ = createStore(false).on(
  turnOffIsNewProductAddedDebouncedFx.done,
  () => false,
);

sample({
  source: cartLength$.map((state, lastState) => ({
    state,
    lastState: lastState?.state,
  })),
  fn: ({ state, lastState }) => {
    return state > lastState;
  },
  filter: ({ state, lastState }) => {
    return state > lastState;
  },
  target: [isNewProductAdded$, turnOffIsNewProductAddedDebouncedFx],
});

export const setIsCartPopupVisible = createEvent();

export const isCartPopupVisible$ = createStore(false).on(
  setIsCartPopupVisible,
  (_, p) => p,
);

cartLength$.watch((value) => {
  if (!value) {
    changeAppliedPromoCode(null);
    changePromoCode(null);
    clearDetailsStorage();
    changeCheckoutComments(null);
    changeCheckoutPurchaseField(null);
    setIsCartPopupVisible(false);
  }
});
