import React from 'react';
import { Link } from 'react-router-dom';

import { T8y } from 'ui';

import style from './multipleLinksMenuWrapper.module.scss';

const MultipleLinksMenuWrapper = ({ linksList, setIsSubMenuOpen }) => {
  return (
    <div className={style.multipleLinksMenuWrapper}>
      <div
        className={style.menuItems}
        style={{ maxHeight: 'calc(100vh - 56px)' }}
      >
        {linksList.map((el, index) => (
          <T8y
            as={el.isExternal ? 'a' : Link}
            to={el.path || '#'}
            href={el.href || '#'}
            variant="t1"
            key={index}
          >
            <div
              className={style.linkWrapper}
              onClick={() => {
                setIsSubMenuOpen(false);
              }}
            >
              {el.title}
            </div>
          </T8y>
        ))}
      </div>
    </div>
  );
};

export default MultipleLinksMenuWrapper;
