import React from 'react';
import { PATHS } from 'AppPaths';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const InformationItemsList = () => {
  return (
    <div className={style.footerLinks}>
      <T8y
        as="a"
        variant="t2v1"
        color="primary"
        href={PATHS.FAQ}
        className={style.footerLink}
      >
        FAQ
      </T8y>
      {/*<Iubenda id="37844183" type="privacy" styling="nostyle">*/}
      {/*  {footer.privacyPolicy}*/}
      {/*</Iubenda>*/}
      {/*<Iubenda id="37844183" type="cookie" styling="nostyle">*/}
      {/*  Cookie Policy*/}
      {/*</Iubenda>*/}
      {/*<Iubenda id="37844183" type="terms-and-conditions" styling="nostyle">*/}
      {/*  {footer.termsConditions}*/}
      {/*</Iubenda>*/}
      <T8y
        as="a"
        variant="t2v1"
        smVariant="t1v1"
        color="primary"
        href="mailto:info@wilkotoolhire.com"
        className={style.footerLink}
      >
        info@wilkotoolhire.com
      </T8y>
      <T8y
        as="a"
        variant="t2v1"
        smVariant="t1v1"
        color="primary"
        href="tel:+447441350385"
        noWrap
      >
        +44 7441 350385
      </T8y>
    </div>
  );
};

export default InformationItemsList;
