import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { products$ } from 'models/product';

import SubCategory from './SubCategory';

import style from '../../../header.module.scss';

const SubCategoryLinks = ({ setIsSubMenuOpen }) => {
  const products = useStore(products$);

  const [categoryImage, setCategoryImage] = useState(null);

  const handleMouseEnter = (item) => {
    !item.childs?.length && setCategoryImage(item.image?.thumb);
  };

  return (
    <div className={style.subMenuWrapper}>
      <ul
        style={{ maxHeight: 'calc(100vh - 56px)' }}
        className={style.dropdownMenu}
      >
        {products.map((item, i) => (
          <li
            onMouseEnter={() => handleMouseEnter(item)}
            onMouseLeave={() => setCategoryImage(null)}
            className={style.listWrapper}
            key={i}
          >
            <SubCategory item={item} setIsSubMenuOpen={setIsSubMenuOpen} />
          </li>
        ))}
      </ul>
      {categoryImage && (
        <div className={style.selectedSubCategoryImgWrapper}>
          <img src={categoryImage} alt="Category" />
        </div>
      )}
    </div>
  );
};

export default SubCategoryLinks;
