import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import {
  addItemToCart,
  changeDetails,
  getFakeOrderFx,
  resetCart,
} from 'models/cart';
import { getDateFromString, getDateInFormat } from 'utils/datesСalculation';
import { getQueryParam } from 'utils/formatStrings';

import { isProductsLoaded$, products$ } from '../models/product';

export const useGetFakeOrder = (location) => {
  const history = useHistory();
  const downloadedProducts = useStore(products$);
  const isProductsLoaded = useStore(isProductsLoaded$);

  const [isLoadingOrderDetail, setIsLoadingOrderDetail] = useState(false);
  const [emailDetails, setEmailDetails] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);

  const id = useMemo(() => {
    return getQueryParam(location.search, 'id');
  }, [location.search]);

  const mode = useMemo(() => {
    return getQueryParam(location.search, 'mode');
  }, [location.search]);

  const prefilledDetailsInfo = useCallback(() => {
    changeDetails({
      name: emailDetails.userName,
      email: emailDetails.userEmail,
      phone: emailDetails.userPhone,
      type: emailDetails.type,
      company_name: emailDetails.company,
      company_id: emailDetails.company_id,
      paymentMethod: null,
    });
  }, [emailDetails]);

  const fakeOrder = useCallback(async () => {
    if (id) {
      setIsLoadingOrderDetail(true);

      try {
        const data = await getFakeOrderFx(id);

        resetCart();

        for (const item of data.products) {
          const productFullInfo = downloadedProducts.find(
            (el) => el.id === item.id,
          );

          if (!productFullInfo) {
            return;
          }

          addItemToCart({
            id: item.id,
            count: item.quantity,
            dates: {
              start: getDateInFormat(
                getDateFromString(item.startDate, 'yyyy-MM-dd'),
                'dd/MM/yyyy',
              ),
              end: getDateInFormat(
                getDateFromString(item.endDate, 'yyyy-MM-dd'),
                'dd/MM/yyyy',
              ),
            },
            delivery: item.delivery,
            permit: item.permitEnabled ? 'yes' : 'no',
            product: productFullInfo,
            postcode: data.postcode,
          });
        }

        setEmailDetails(data || null);

        if (mode === 'checkout') {
          history.replace(PATHS.CHECKOUT);
        }
      } catch (e) {
        if (e.response?.status === 404) {
          setIsNotFound(true);
        } else {
          toast.error('Something went wrong');
        }
      } finally {
        setIsLoadingOrderDetail(false);
      }
    }
  }, [id, mode, downloadedProducts, history]);

  useEffect(() => {
    if (!isProductsLoaded) {
      return;
    }

    fakeOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isProductsLoaded]);

  useEffect(() => {
    if (!isProductsLoaded) {
      return;
    }

    if (emailDetails) {
      prefilledDetailsInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailDetails, isProductsLoaded]);

  return useMemo(
    () => ({ isLoadingOrderDetail, isNotFound }),
    [isLoadingOrderDetail, isNotFound],
  );
};
