import { useCallback, useEffect, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import { deleteItemFromCart, editItemInCart } from 'models/cart';
import { diffInDays, getDateFromString } from 'utils/datesСalculation';

export const useItemInCart = ({ itemInCart, type, quantity, isLoading }) => {
  const [productInCartCount, setProductInCartCount] = useState(
    itemInCart.count,
  );

  useEffect(
    () => {
      if (type) {
        return;
      }

      setProductInCartCount(itemInCart.count);

      if (!isLoading && quantity !== productInCartCount) {
        setProductInCartCount(quantity);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, itemInCart, quantity],
  );

  const minQuantity = useMemo(
    () => (type === 'reqExtra' ? itemInCart.count : 1),
    [type, itemInCart.count],
  );

  const productInfo = useMemo(() => {
    return itemInCart.product || {};
  }, [itemInCart]);

  const productTitle = productInfo.name;

  const { reqExtraList, optionalExtraList } = useMemo(() => {
    if (!productInfo?.attributes) {
      return { reqExtraList: [], optionalExtraList: [] };
    }

    return productInfo.attributes.reduce(
      (acc, el) => {
        if (el.slug === 'required-extra') {
          acc.reqExtraList.push({
            ...el,
            quantity:
              itemInCart.reqExtra.find((item) => item.id === el.id)?.quantity ||
              itemInCart.count,
          });
        }

        if (el.slug === 'optional-extra' || el.slug === 'optional-sale-extra') {
          acc.optionalExtraList.push({
            ...el,
            quantity:
              itemInCart.optionalExtra
                .concat(itemInCart.optionalSaleExtra)
                .find((item) => item.id === el.id)?.quantity || 0,
          });
        }

        return acc;
      },
      { reqExtraList: [], optionalExtraList: [] },
    );
  }, [itemInCart, productInfo]);

  const isLimit = useMemo(() => {
    return (
      isNil(productInfo.max_quantity) || quantity >= productInfo.max_quantity
    );
  }, [quantity, productInfo.max_quantity]);

  const daysCount = useMemo(() => {
    if (!itemInCart.dates.start || !itemInCart.dates.end) {
      return 0;
    }

    const endDate = getDateFromString(itemInCart.dates?.end);
    const startDate = getDateFromString(itemInCart.dates?.start);

    return diffInDays(endDate, startDate) || 0;
  }, [itemInCart.dates]);

  const itemPriceText = daysCount === 1 ? 'per 1 day' : `per ${daysCount} days`;

  const changeExtra = useCallback(
    (extras) => {
      editItemInCart({
        ...itemInCart,
        reqExtra: extras.reqExtra,
        optionalExtra: extras.optionalExtra,
        optionalSaleExtra: extras.optionalSaleExtra,
      });
    },
    [itemInCart],
  );

  const handleChangeCount = useCallback(
    (count) => {
      if (count === itemInCart.count) {
        return;
      }
      editItemInCart({ ...itemInCart, count: count });
    },
    [itemInCart],
  );

  const onDateChangeCallback = useCallback(
    ({ startDate, endDate }) => {
      editItemInCart({
        id: itemInCart.id,
        dates: { start: startDate, end: endDate },
      });
    },
    [itemInCart.id],
  );

  const handleDelete = useCallback(() => {
    deleteItemFromCart(itemInCart.id);
  }, [itemInCart]);

  return useMemo(
    () => ({
      productTitle,
      itemPriceText,
      productInfo,
      reqExtraList,
      optionalExtraList,
      daysCount,
      onDateChangeCallback,
      productInCartCount,
      minQuantity,
      handleChangeCount,
      isLimit,
      changeExtra,
      handleDelete,
    }),
    [
      productTitle,
      itemPriceText,
      productInfo,
      reqExtraList,
      optionalExtraList,
      daysCount,
      onDateChangeCallback,
      productInCartCount,
      minQuantity,
      handleChangeCount,
      isLimit,
      changeExtra,
      handleDelete,
    ],
  );
};
