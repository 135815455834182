import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { LAPTOP_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { sendGAEvent } from 'googleAnalytics';
import { useMatchMedia } from 'hooks';
import { validPostcode$ } from 'models/product';

import LazyLoadingComponent from 'features/LazyLoadingComponent';

import MainProductPrice from './MainProductPrice';

import { Button, T8y } from 'ui';

import defaultImageProduct from 'assets/images/pictures/wilko/product-fallback.svg';

import style from './bigProductCard.module.scss';

export const BigProductCard = ({ product, testId }) => {
  const validPostcode = useStore(validPostcode$);

  const viewBtnRef = useRef(null);

  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);

  //attribute offset="140" for lazy was tested on iPhone 14 Pro
  return (
    <Link
      data-testid={testId}
      to={PATHS.PRODUCT(product.slug)}
      className={style.productCard}
      onClick={() => {
        sendGAEvent(
          'view_item',
          [
            {
              item_id: product.id,
              item_name: product.name,
              coupon: '',
              discount: 0,
              item_category: 'skip',
              location_id: validPostcode ?? '',
              price: product.priceFrom,
              quantity: 1,
              currency: 'GBP',
            },
          ],
          {
            transaction_id: '',
            value: product.priceFrom,
            tax: 0,
            shipping: 0,
            currency: 'GBP',
            coupon: '',
          },
        );
      }}
    >
      <div className={style.productImageWrapper}>
        <LazyLoadingComponent className={style.lazyContainer} once offset={140}>
          <picture>
            <source srcSet={product.image?.webp?.medium} type="image/webp" />
            <img
              className={style.productImage}
              src={product.image?.medium || defaultImageProduct}
              alt="ProductParts img"
            />
          </picture>
        </LazyLoadingComponent>
      </div>
      <div className={style.productInfoWrapper}>
        <div className={style.productInfoDetails}>
          <T8y
            className={clsx(style.productNameWrapper, style.productETHName)}
            as="p"
            variant="h2"
            xsVariant="t1"
            bold
          >
            {product.name}
          </T8y>
          {isLaptop && <MainProductPrice mainPrice={product.priceFrom} />}
          <T8y variant="t1" xsVariant="t1v1" bold mb>
            Product dimensions
          </T8y>
          <T8y color="midGray" mb2>
            {product.dimensions}
          </T8y>
          <ul className={style.details}>
            {product.details?.map((el, i) => (
              <T8y key={i} as="li" variant="t1" mdVariant="t2" mb>
                {el}
              </T8y>
            ))}
          </ul>
          {isLaptop && (
            <Button
              ref={viewBtnRef}
              className={style.viewDetailsBtn}
              data-testid="viewDetailsBtn"
            >
              View details
            </Button>
          )}
        </div>
        {!isLaptop && (
          <div className={style.productInfoPrice}>
            <MainProductPrice mainPrice={product.priceFrom} />
            <div className={style.viewDetailsBtnWrapper}>
              <Button
                ref={viewBtnRef}
                className={style.viewDetailsBtn}
                data-testid="viewDetailsBtn"
              >
                View details
              </Button>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};
