import React from 'react';
import { Link } from 'react-router-dom';
import { useDisclosureState } from 'reakit';
import { PATHS } from 'AppPaths';
import { useStore } from 'effector-react';
import { useSectionAutoCollapsing } from 'layout/Footer/useSectionAutoCollapsing';
import { products$ } from 'models/product';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const DesktopCategoriesList = () => {
  const products = useStore(products$);

  const disclosure = useDisclosureState();

  useSectionAutoCollapsing(disclosure);

  return (
    <div>
      <T8y variant="t1v1" mb2 bold className={style.title}>
        Hire by skip size
      </T8y>
      <div>
        {products.map(({ name, slug }, i) => (
          <T8y
            key={i}
            as={Link}
            to={PATHS.PRODUCT(slug)}
            variant="t2v1"
            color="primary"
            className={style.footerLink}
          >
            {name}
          </T8y>
        ))}
      </div>
    </div>
  );
};

export default DesktopCategoriesList;
