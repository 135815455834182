import { getDateFromString, getDateInFormat } from './datesСalculation';

export const getFormattedProductsForTotalPriceRequest = ({
  id,
  count: quantity,
  delivery,
  permit,
  dates,
}) => {
  const template = 'yyyy-MM-dd';

  return {
    id,
    quantity,
    startDate:
      dates && getDateInFormat(getDateFromString(dates.start), template),
    endDate: dates && getDateInFormat(getDateFromString(dates.end), template),
    delivery,
    permitEnabled: permit === 'yes',
  };
};
