import React from 'react';
import clsx from 'clsx';

import { T8y } from 'ui';

import { ReactComponent as DatePickerIcon } from 'assets/images/icons/calendarIcon.svg';

import style from './datePickerDisclosureItem.module.scss';

const LABELS = { hireStart: 'Delivery date', hireEnd: 'Collection date' };

const DatePickerDisclosureItem = ({
  date,
  isDatesHighlighted,
  isInCalendar,
  titleSlug,
}) => {
  return (
    <div
      className={clsx(
        date
          ? style.datePickerDisclosureItemWithDate
          : style.datePickerDisclosureItem,
        isDatesHighlighted && !date && style.highlightedDate,
      )}
    >
      <div>
        {date ? (
          <T8y
            variant="t5"
            color="newGray"
            boldLight
            className={style.datePickerDisclosureItemHeader}
          >
            {LABELS[titleSlug]}
          </T8y>
        ) : (
          <T8y variant="t2" xsVariant="t3" color="newGray" boldLight>
            {LABELS[titleSlug]}
          </T8y>
        )}
        <T8y
          color="mainGray"
          variant="t2"
          className={style.datePickerDisclosureItemDate}
          boldLight
        >
          {date}
        </T8y>
      </div>
      <DatePickerIcon
        width={16}
        height={16}
        className={isInCalendar ? style.calendarInputIcon : undefined}
      />
    </div>
  );
};

export default DatePickerDisclosureItem;
