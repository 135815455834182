import React from 'react';
import { SMALL_SCREEN } from 'consts';
import { useMatchMedia } from 'hooks';

import CallSign from './CallSign';

import { T8y } from 'ui';

import style from '../header.module.scss';

const Phone = () => {
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  if (isSmallScreen) {
    return <CallSign />;
  }

  return (
    <T8y
      className={style.phoneNumberLink}
      color="light"
      as="a"
      href="tel:+447441350385"
      noWrap
    >
      +44 7441 350385
    </T8y>
  );
};

export default Phone;
