import MainContentBlock from '../MainContentBlock';

import { Row, T8y } from 'ui';

import { ReactComponent as OnlineBookingIcon } from 'assets/images/icons/calendarIcon.svg';
import { ReactComponent as CostIcon } from 'assets/images/icons/hassleFreeBooking/cost.svg';
import { ReactComponent as FlagIcon } from 'assets/images/icons/hassleFreeBooking/flag.svg';
import { ReactComponent as SkipIcon } from 'assets/images/icons/hassleFreeBooking/skip.svg';

import style from './hassleFreeBooking.module.scss';

const items = [
  { icon: <CostIcon />, text: 'Low cost' },
  { icon: <OnlineBookingIcon />, text: 'Book online' },
  { icon: <SkipIcon />, text: 'Reliable delivery' },
  { icon: <FlagIcon />, text: 'UK-wide service' },
];

export const HassleFreeBooking = () => {
  return (
    <MainContentBlock wrapperClassName={style.hassleFreeBookingWrapper}>
      <div className={style.hassleFreeBooking}>
        <Row justify="space" xsDirection="column">
          {items.map((el, i) => (
            <Row
              key={i}
              align="center"
              mr2
              className={style.hassleFreeBookingItem}
            >
              {el.icon}
              <T8y variant="t1" ml>
                {el.text}
              </T8y>
            </Row>
          ))}
        </Row>
      </div>
    </MainContentBlock>
  );
};
