import React from 'react';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';

import { Row, T8y } from 'ui';

import { ReactComponent as ArrowBack } from 'assets/images/icons/arrowBack.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/carat-right.svg';

import style from '../header.module.scss';

const SubMenuCompany = ({ id, openId, setOpenId, close }) => {
  const isHire = openId === id;

  return (
    <div className={style.category}>
      <Row
        justify="stretch"
        align="center"
        className={clsx(
          style.name,
          style.border,
          openId > 0 && !isHire && style.none,
        )}
        onClick={() => {
          setOpenId(isHire ? 0 : 3);
        }}
        data-testid="mobile-company-disclosure"
      >
        {isHire ? (
          <>
            <Row align="center">
              <ArrowBack className={style.backArrow} />{' '}
              <T8y variant="t1" color="light" className={style.menuTitle}>
                Back
              </T8y>
            </Row>
            <T8y variant="t3" className={style.itemName}>
              Information
            </T8y>
          </>
        ) : (
          <>
            <T8y variant="t1" color="light" className={style.menuTitle}>
              Information
            </T8y>
            <ArrowRight />
          </>
        )}
      </Row>

      {isHire && (
        <T8y
          as="a"
          rel="noopener noreferrer"
          href={PATHS.FAQ}
          onClick={close}
          variant="t1"
          className={style.categoryOnly}
          capitalize
        >
          FAQ
        </T8y>
      )}
    </div>
  );
};

export default SubMenuCompany;
