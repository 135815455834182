import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { sendGAEvent } from 'googleAnalytics';
import { validPostcode$ } from 'models/product';

import LazyLoadingComponent from 'features/LazyLoadingComponent';

import MainProductPrice from './MainProductPrice';

import { Button, T8y } from 'ui';

import defaultImageProduct from 'assets/images/pictures/wilko/product-fallback.svg';

import style from './productCard.module.scss';

export function ProductCard({ product, testId, isFixedWidth, withoutBorder }) {
  const validPostcode = useStore(validPostcode$);

  const viewBtnRef = useRef(null);

  return (
    <Link
      data-testid={testId}
      to={PATHS.PRODUCT(product.slug)}
      className={clsx(
        style.productCard,
        isFixedWidth && style.fixCardWidth,
        withoutBorder && style.withoutBorder,
      )}
      onClick={() => {
        sendGAEvent(
          'view_item',
          [
            {
              item_id: product.id,
              item_name: product.name,
              coupon: '',
              discount: 0,
              item_category: 'skip',
              location_id: validPostcode ?? '',
              price: product.priceFrom,
              quantity: 1,
              currency: 'GBP',
            },
          ],
          {
            transaction_id: '',
            value: product.priceFrom,
            tax: 0,
            shipping: 0,
            currency: 'GBP',
            coupon: '',
          },
        );
      }}
    >
      <div>
        <div className={style.productImageWrapper}>
          <LazyLoadingComponent className={style.lazyContainer} once>
            <picture>
              <source srcSet={product.image?.webp?.medium} type="image/webp" />
              <img
                className={style.productImage}
                src={product.image?.medium || defaultImageProduct}
                alt="ProductParts img"
              />
            </picture>
          </LazyLoadingComponent>
        </div>
        <T8y className={style.productNameWrapper} as="p" variant="t1" bold>
          {product.name}
        </T8y>
        <div className={style.mainProductPriceWrapper}>
          <MainProductPrice mainPrice={product.priceFrom} />
        </div>
        <ul className={style.details}>
          {product.details?.map((el, i) => (
            <T8y
              variant="t2v1"
              xsVariant={withoutBorder ? 't4' : 't2v1'}
              as="li"
              key={i}
            >
              {el}
            </T8y>
          ))}
        </ul>
      </div>
      <div className={style.viewDetailsBtnWrapper}>
        <Button
          ref={viewBtnRef}
          className={style.viewDetailsBtn}
          data-testid="viewDetailsBtn"
        >
          Hire {product.name?.toLowerCase()}
        </Button>
      </div>
    </Link>
  );
}
