import { useCallback, useEffect, useMemo, useState } from 'react';
import { add, parse } from 'date-fns';
import { useStore } from 'effector-react';
import {
  changeDatesRange,
  changeFocusedPickerDay,
  picker_state$,
} from 'models/cart';
import {
  diffInDays,
  formatDate,
  getDateFromString,
} from 'utils/datesСalculation';

export const useCalendarSettings = (product = {}, dates) => {
  const state = useStore(picker_state$);

  const [selectedValues, setSelectedValues] = useState({
    startDate: dates?.start,
    endDate: dates?.end,
  });

  useEffect(() => {
    return () => {
      setSelectedValues({
        startDate: null,
        endDate: null,
      });
      changeFocusedPickerDay('start');
    };
  }, []);

  const onDatesChanges = useCallback(
    (e, isPrefilled) => {
      const startDate = formatDate(e.startDate);
      let endDate = formatDate(e.endDate);

      if (!isPrefilled && state?.focusedInput === 'startDate') {
        endDate = null;
      }

      setSelectedValues({ startDate, endDate });
      changeDatesRange({ startDate, endDate });
    },
    [state],
  );

  const minBookingDays = useMemo(() => {
    if (!product || !product.min_period_days) {
      return 1;
    }

    return product.min_period_days;
  }, [product]);

  const maxBookingDate = useMemo(() => {
    if (!selectedValues.startDate) {
      return;
    }
    return add(getDateFromString(selectedValues.startDate), { days: 14 });
  }, [selectedValues.startDate]);

  const hiringDaysCount = useMemo(() => {
    if (selectedValues.endDate && selectedValues.startDate) {
      return diffInDays(
        parse(selectedValues.endDate, 'dd/MM/yyyy', new Date()),
        parse(selectedValues.startDate, 'dd/MM/yyyy', new Date()),
      );
    }
    return 0;
  }, [selectedValues]);

  const minBookingDate = useMemo(
    () =>
      new Date(
        new Date().toLocaleString('en-US', {
          timeZone: 'Europe/London',
        }),
      ),
    [],
  );

  return useMemo(
    () => ({
      selectedValues,
      onDatesChanges,
      hiringDaysCount,
      minBookingDays,
      minBookingDate,
      maxBookingDate,
    }),
    [
      selectedValues,
      onDatesChanges,
      hiringDaysCount,
      minBookingDays,
      minBookingDate,
      maxBookingDate,
    ],
  );
};
