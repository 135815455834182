import { useCallback, useEffect } from 'react';
import * as CR from 'models/cart';

import { CART } from 'api';

export const useTotalPriceCalculating = ({
  type,
  cartFormattedData,
  appliedPromoCode,
  postcode,
}) => {
  const getTotalPrice = useCallback(
    (signal) => {
      CR.setTotalPriceLoading();

      if (!cartFormattedData || !cartFormattedData.length) {
        CR.setTotalPrice({});
        return;
      }

      CART.getTotalPrice(
        {
          ...(appliedPromoCode && { promocode: appliedPromoCode }),
          products: cartFormattedData,
          postcode,
        },
        signal,
      )
        .then(({ data }) => {
          CR.setTotalPrice(data.data);
        })
        .catch((err) => {
          if (err.message !== 'canceled') {
            CR.setTotalPrice({});
          }
          console.warn(err);
        });
    },
    [cartFormattedData, appliedPromoCode, postcode],
  );

  useEffect(() => {
    if (window.AbortController) {
      const controller = new window.AbortController();

      getTotalPrice(controller.signal);

      return () => {
        controller.abort();
      };
    } else {
      getTotalPrice();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartFormattedData, type, appliedPromoCode]);
};
