import React from 'react';
import clsx from 'clsx';

import { Button } from 'ui';

import style from './checkoutBtn.module.scss';

const CheckoutBtn = ({
  text,
  onClick,
  isSelected,
  additionalClass,
  ...rest
}) => {
  return (
    <Button
      className={clsx(
        style.checkoutBtn,
        isSelected && style.selected,
        additionalClass,
      )}
      onClick={onClick}
      {...rest}
    >
      {text}
    </Button>
  );
};

export default CheckoutBtn;
