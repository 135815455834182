import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { LAPTOP_SCREEN, SMALL_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { useMatchMedia } from 'hooks';
import CartSingWrapper from 'layout/Header/CartSignWrapper';
import Message from 'layout/Header/Message';
import MobileNav from 'layout/Header/MobileNav';
import Nav from 'layout/Header/Nav';
import { isShowMessageIcon$, setIsMenuOpen } from 'models/common';

import Phone from '../Phone';

import { BreadCrumbs, Row } from 'ui';

import wilkoSkipHireLogo from 'assets/images/icons/wilkoSkipHireLogo.png';

import style from './commonHeader.module.scss';

const CommonHeader = ({ isMenuOpen }) => {
  const isShowMessageIcon = useStore(isShowMessageIcon$);

  const location = useLocation();

  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const [openId, setOpenId] = useState(0);

  const close = useCallback(() => setIsMenuOpen(false), []);

  const isEmptyHeader = useMemo(() => {
    return location.pathname.includes(PATHS.CHECKOUT);
  }, [location]);

  const isShowBreadCrumbs = !isEmptyHeader && (!isMenuOpen || !isLaptop);

  return (
    <>
      <div className={style.headerNav}>
        <Row
          justify="stretch"
          align="center"
          fullWidth
          noWrap
          className={style.headerWrapper}
        >
          {isLaptop && (
            <MobileNav
              openId={openId}
              setOpenId={setOpenId}
              close={close}
              isMenuOpen={isMenuOpen}
            />
          )}
          <Row
            align="center"
            smJustify="center"
            smFullWidth
            noWrap
            className={style.navWrapper}
          >
            <Link
              to={PATHS.ROOT}
              className={style.logoWrapper}
              aria-label="EasyHire"
            >
              <img
                src={wilkoSkipHireLogo}
                alt="logo"
                className={isSmallScreen ? style.mobileLogo : style.logo}
              />
            </Link>
            {!isLaptop && !isEmptyHeader && <Nav />}
          </Row>
          <Row
            noWrap
            align="center"
            className={clsx(
              style.iconsWrapper,
              style.basketAndLang,
              style.basketAndLangBigAmount,
            )}
          >
            <Phone />
            {!isLaptop && isShowMessageIcon && <Message />}
            {!isEmptyHeader && <CartSingWrapper close={close} />}
          </Row>
        </Row>
      </div>
      {isShowBreadCrumbs && <BreadCrumbs />}
    </>
  );
};

export default CommonHeader;
