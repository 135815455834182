import React from 'react';
import clsx from 'clsx';

import SubCategoryLinks from './SubCategoryLinks';

import style from '../../header.module.scss';

const Catalog = ({ setIsSubMenuOpen }) => {
  return (
    <div className={style.menuWrapper}>
      <div className={style.dropdownMenuWrapper}>
        <div
          style={{ maxHeight: 'calc(100vh - 100px)' }}
          className={clsx(style.mainMenu, style.dropdownMenu)}
        >
          <SubCategoryLinks setIsSubMenuOpen={setIsSubMenuOpen} />
        </div>
      </div>
    </div>
  );
};

export default Catalog;
