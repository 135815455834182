import { useLocation } from 'react-router-dom';
import { PATHS } from 'AppPaths';

const footerBlockedUrls = [PATHS._CHECKOUT, PATHS._RESERVATION];

export const useGetLayoutConfiguration = () => {
  const { pathname } = useLocation();

  return {
    isFooter: !footerBlockedUrls.some((e) => pathname.includes(e)),
  };
};
