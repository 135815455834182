import React from 'react';

import { Row, T8y } from 'ui';

import { ReactComponent as EasyHireLogo } from 'assets/images/icons/easyHireFooterLogo.svg';
import { ReactComponent as AmericanExpress } from 'assets/images/icons/paymentOptions/AmericanExpress_sm.svg';
// import { ReactComponent as ApplePay } from 'assets/images/icons/paymentOptions/apple-pay.svg';
// import { ReactComponent as GooglePay } from 'assets/images/icons/paymentOptions/GooglePay.svg';
import { ReactComponent as Mastercard } from 'assets/images/icons/paymentOptions/Mastercard_sm.svg';
import { ReactComponent as Visa } from 'assets/images/icons/paymentOptions/Visa_sm.svg';

import style from './poweredBy.module.scss';

const PoweredBy = () => {
  return (
    <Row direction="column" smAlign="center" className={style.poweredByWrapper}>
      <T8y variant="t1v1" mb2 bold className={style.title}>
        This website is powered by
      </T8y>
      <EasyHireLogo className={style.easyHireLogo} />
      <T8y color="midGray" mt2 className={style.easyHireText}>
        easyHire Technologies Ltd operates a global, fast-growing, cloud-based
        technology platform and franchise network for the multi-billion pound
        sterling equipment hire industry.
      </T8y>
      <T8y variant="t1v1" bold className={style.securePayments}>
        Secure Payments
      </T8y>
      <Row smJustify="center" fullWidth>
        <Mastercard className={style.cardIcon} />
        <Visa className={style.cardIcon} />
        <AmericanExpress className={style.cardIcon} />
        {/*<ApplePay className={style.cardIcon} />*/}
        {/*<GooglePay className={style.cardIcon} />*/}
      </Row>
    </Row>
  );
};

export default PoweredBy;
