import { LAPTOP_SCREEN, SMALL_SCREEN } from 'consts';
import { useMatchMedia } from 'hooks';

import { MobileHeroBanner } from './MobileHeroBanner';

import { T8y } from 'ui';

import heroImage from 'assets/images/pictures/hero.jpg';

import style from './heroBanner.module.scss';

export const HeroBanner = () => {
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);
  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);

  return isSmallScreen ? (
    <MobileHeroBanner />
  ) : (
    <div className={style.bannerWrapper}>
      <div className={style.bannerContainer}>
        <div className={style.bannerContent}>
          <T8y as="h1" color="light" className={style.bannerContentTitle}>
            Great value skip hire with hassle-free booking!
          </T8y>

          <T8y
            as="p"
            color="light"
            variant="t1"
            className={style.bannerContentText}
          >
            If you’re after a low cost, reliable, and fuss-free skip hire
            service, you’re in the right place!
          </T8y>
        </div>
        {!isLaptop && (
          <div className={style.bannerImgWrapper}>
            <img src={heroImage} alt="Banner" className={style.heroImage} />
          </div>
        )}
      </div>
    </div>
  );
};
