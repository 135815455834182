import React from 'react';
import { Radio as RadioR } from 'reakit/Radio';
import clsx from 'clsx';

import { Row, T8y } from 'ui';

import style from './radio.module.scss';

export const Radio = React.forwardRef(
  (
    {
      size = 1.3,
      variant = 't2',
      xsVariant = variant,
      boldLight,
      color = 'secondary',
      text,
      value = '',
      className,
      disable,
      circleClassName,
      circleActiveClassName,
      dataTestId,
      ...rest
    },
    ref,
  ) => {
    return (
      <Row
        align="center"
        as="label"
        className={clsx(style.label, className, {
          [style.disable]: disable,
        })}
        data-testid={dataTestId}
        noWrap
      >
        <RadioR {...rest} value={value} ref={ref} disabled={disable} />
        <div
          className={clsx(style.button, circleClassName, {
            [style.buttonActive]: rest.state === value,
            [circleActiveClassName]: rest.state === value,
          })}
          style={{
            width: `${size + 0.5}rem`,
            height: `${size + 0.5}rem`,
            marginRight: `${size / 2}rem`,
          }}
        />
        {text && (
          <T8y
            variant={variant}
            xsVariant={xsVariant}
            color={color}
            boldLight={boldLight}
          >
            {text}
          </T8y>
        )}
      </Row>
    );
  },
);
