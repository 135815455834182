import React from 'react';
import { SMALL_SCREEN } from 'consts';
import { useMatchMedia } from 'hooks/useMatchMedia';
import MobileDropDown from 'layout/Footer/MobileDropDown';

import InformationItemsList from './InformationItemsList';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const Information = () => {
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  return isSmallScreen ? (
    <MobileDropDown title="Information">
      <InformationItemsList />
    </MobileDropDown>
  ) : (
    <>
      <T8y variant="t1v1" mb2 bold className={style.title}>
        Information
      </T8y>
      <InformationItemsList />
    </>
  );
};

export default Information;
