import React from 'react';

import { Row, T8y } from 'ui';

import { ReactComponent as EasyToolhireLogo } from 'assets/images/icons/easyToolhire.svg';
import wilkoSkipHireLogo from 'assets/images/icons/wilkoSkipHireLogo.png';

import style from './attachmentTop.module.scss';

const AttachmentTop = () => {
  return (
    <Row
      align="center"
      justify="center"
      className={style.footerAttachmentTopWrapper}
    >
      <Row
        align="center"
        justify="stretch"
        smDirection="column"
        smAlign="center"
        noWrap
        className={style.footerAttachmentContainer}
      >
        <a href={window.location.origin} className={style.footerLogo}>
          <img src={wilkoSkipHireLogo} alt="logo" />
        </a>

        <Row align="center" justify="center">
          <T8y bold color="light" className={style.wilkoPoweredBy}>
            Powered by
          </T8y>
          <EasyToolhireLogo className={style.easyToolhireLogo} />
        </Row>
      </Row>
    </Row>
  );
};

export default AttachmentTop;
