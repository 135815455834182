import React, { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { validateEmailReg } from 'consts';
import { useStore } from 'effector-react';
import { useShowMessageIcon } from 'hooks';
import { $UTMData } from 'models/UTMData';

import { ENQUIRY } from 'api';

import { Button, Checkbox, PhoneField, T8y } from 'ui';

import style from './enquiryLead.module.scss';

export const EnquiryLead = ({ source, innerRef }) => {
  const UTMData = useStore($UTMData);

  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isCheckBoxInfo, setIsCheckBoxInfo] = useState(false);

  const enquiryRef = useRef(null);

  useShowMessageIcon();

  const { register, handleSubmit, errors, reset, control } = useForm({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async (data) => {
      setIsSendingRequest(true);

      const formData = {
        source,
        lead_page_url: window.location.href,
      };

      try {
        const finalData = {
          ...data,
          ...formData,
          ...(UTMData && { utm: { ...UTMData } }),
          origin: 'wilkotoolhire.com',
        };

        const leadFinalData = {
          ...finalData,
          lead_type: 'enquiry',
          page_url: window.location.href,
          source,
        };

        await ENQUIRY.sendEnquiry(leadFinalData);

        setIsPost(true);

        toast.success('Your request has been sent successfully!');

        reset();
        setIsCheckBoxInfo(false);
      } catch (e) {
        console.warn(e);
        toast.error('Something went wrong');
      } finally {
        setIsSendingRequest(false);
      }
    },
    [UTMData, reset, source],
  );

  return (
    <div className={style.wrapper} id="enquiryForm" ref={innerRef}>
      <div className={style.wrapperInner}>
        <T8y
          className={style.enquiryTitle}
          ref={enquiryRef}
          bold
          center
          fullWidth
        >
          Unsure which waste removal service is best for you?
          <br />
          Get in touch today!
        </T8y>

        <T8y variant="t1" xsVariant="t1v1" center mb3 mt2>
          Please feel free to contact us if you have any questions. We’ll be
          happy to help!
        </T8y>

        <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
          <div className={style.formContent}>
            <div className={style.short}>
              <input
                name="name"
                ref={register({
                  validate: (value) => !!value?.trim() || 'Required field*',
                })}
                placeholder="Full Name*"
                className={clsx(style.input, style.enquiryInput)}
              />

              <T8y variant="t4" color="danger" as="p" className={style.error}>
                {errors.name && errors.name.message}
              </T8y>
            </div>

            <div className={style.short}>
              <PhoneField
                className={clsx(style.input, style.enquiryPhoneInputWrapper)}
                placeholder="Phone Number*"
                control={control}
              />
              <T8y variant="t4" color="danger" as="p" className={style.error}>
                {errors.phone && errors.phone.message}
              </T8y>
            </div>
          </div>

          <div className={style.formContent}>
            <div className={style.full}>
              <input
                name="email"
                type="email"
                ref={register({
                  required: 'Required field*',
                  pattern: {
                    value: validateEmailReg,
                    message: 'Incorrect email',
                  },
                })}
                placeholder="Email Address*"
                className={clsx(style.input, style.enquiryInput)}
              />
              <T8y variant="t4" color="danger" as="p" className={style.error}>
                {errors.email && errors.email.message}
              </T8y>
            </div>
          </div>
          <textarea
            ref={register}
            name="message"
            rows="3"
            placeholder="Detail your enquiry or any questions here"
            className={style.message}
          />

          <Checkbox
            type="button"
            checked={isCheckBoxInfo}
            onChange={() => setIsCheckBoxInfo(!isCheckBoxInfo)}
            label={
              <span>
                I consent to sharing my information with easyToolhire. We will
                never share your information with third parties without your
                permission.{' '}
                <T8y
                  as={Link}
                  to={PATHS.PRIVACY_POLICY}
                  target="_blank"
                  color="wilkoBlack"
                  asLink
                >
                  See Privacy Notice here
                </T8y>
              </span>
            }
          />

          <Button
            className={style.button}
            type="submit"
            disabled={!isCheckBoxInfo || isSendingRequest || isPost}
          >
            Submit enquiry
          </Button>
        </form>

        {isPost && (
          <div className={style.thankYouMsg}>
            <T8y color="success" center>
              Thank you for submitting your enquiry!
            </T8y>
            <T8y color="success" center>
              We’ll contact you shortly.
            </T8y>
          </div>
        )}
      </div>
    </div>
  );
};
