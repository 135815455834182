import {
  differenceInDays,
  format,
  getDay,
  isAfter,
  isSameDay,
  parse,
} from 'date-fns';
import { enGB } from 'date-fns/locale';

import { capitalizeFirstLetter } from './formatStrings';

const DAYS_OF_THE_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getDayOfWeek = (date) => {
  return DAYS_OF_THE_WEEK[getDay(date)];
};

export const diffInDays = (dateLeft, dateRight) => {
  return differenceInDays(dateLeft, dateRight) || 1;
};

export const getTimestampsDifference = (timestamp1, timestamp2) => {
  const d1 = new Date(timestamp1);
  const d2 = new Date(timestamp2);

  const diff = d2 - d1;
  //minutes
  return Math.floor(diff / 60e3);
};

export const formatDate = (date) => {
  if (!date) return null;
  return date?.toLocaleString('en-GB').slice(0).split(',')[0];
};

export const getDateFromString = (date, format = 'dd/MM/yyyy') => {
  if (!date) return null;
  return parse(date, format, new Date());
};

export const getDateInFormat = (date, template = 'dd/MM/yyyy HH:mm') => {
  if (!date) return null;
  return format(date, template);
};

export const getLocalizedMonthLabel = (currLang, month) => {
  return enGB.localize.month(month, { width: 'wide' });
};

export const getFormattedHireDates = (
  lang = 'en-gb',
  start = '01/01/2020',
  end = '01/01/2020',
) => {
  const startDate = getDateFromString(start);
  const endDate = getDateFromString(end);

  const monthName = new Intl.DateTimeFormat(lang, { month: 'short' }).format;
  const dayOfWeekName = new Intl.DateTimeFormat(lang, { weekday: 'short' })
    .format;

  const startDateDay = new Date(startDate).getDate();
  const startDateDayOfWeek = capitalizeFirstLetter(dayOfWeekName(startDate));
  const startDateYear = new Date(startDate).getFullYear();
  const startDateMonth = capitalizeFirstLetter(monthName(new Date(startDate)));

  const endDateDay = new Date(endDate).getDate();
  const endDateDayOfWeek = capitalizeFirstLetter(dayOfWeekName(endDate));
  const endDateYear = new Date(endDate).getFullYear();
  const endDateMonth = capitalizeFirstLetter(monthName(new Date(endDate)));

  return {
    startDate: `${startDateDayOfWeek}, ${startDateMonth} ${startDateDay}, ${startDateYear}`,
    endDate: `${endDateDayOfWeek}, ${endDateMonth} ${endDateDay}, ${endDateYear}`,
  };
};

export const checkIsValidStartDate = (initialStartDate) => {
  const start = new Date();

  // check if start date is same or more then min possible start date
  // check if that date is available
  return isSameDay(initialStartDate, start) || isAfter(initialStartDate, start);
};
