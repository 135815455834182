import { Row, T8y } from 'ui';

import checkIcon from 'assets/images/icons/wilko-tick@2x.png';

import style from './benefits.module.scss';

const copies = [
  'We have a brilliant range of skips available to hire, making your home & garden projects that much easier!',
  'As one of the UK’s leading skip hire providers, we can help you dispose of waste from any type of project, and any size.',
  'Our extensive range of skips are available for delivery to any UK postcode, taking the stress out of tidying up!',
];

const benefits = [
  'A fixed hire period of up to 14 days',
  'Great for bulky waste clearances',
  'Book online hassle free',
  'A range of sizes to suit any requirement',
];

export const Benefits = () => {
  return (
    <Row justify="stretch" mdDirection="column" noWrap>
      <div className={style.benefitsSection}>
        <T8y variant="h1" xsVariant="h2" mb2>
          Cost-effective skip hire made easy
        </T8y>
        {copies.map((el, i) => (
          <T8y key={i} variant="t1" xsVariant="t1v1" mb2>
            {el}
          </T8y>
        ))}
      </div>
      <div className={style.benefitsSection}>
        <T8y variant="h1" xsVariant="h2" mb2>
          The benefits of skip hire
        </T8y>
        {benefits.map((el, i) => (
          <Row key={i} noWrap>
            <img
              src={checkIcon}
              className={style.benefitIcon}
              alt="benefit icon"
            />
            <T8y variant="t1" xsVariant="t1v1" bold ml mb2>
              {el}
            </T8y>
          </Row>
        ))}
      </div>
    </Row>
  );
};
