import React from 'react';

import { T8y } from 'ui';

import style from './mainProductPrice.module.scss';

const MainProductPrice = ({ mainPrice, defaultHiringPeriodProduct }) => {
  return (
    <div className={style.priceWrapper}>
      {!!mainPrice && defaultHiringPeriodProduct !== 7 && (
        <T8y variant="t1" xsVariant="t1v1" as="span" bold>
          Price starting from (Excl. VAT)
        </T8y>
      )}

      <div className={style.productPriceWrapper}>
        {!!mainPrice && (
          <T8y
            variant="h2"
            xsVariant="t1"
            as="span"
            className={style.productPriceBig}
            bold
          >
            {'£' + mainPrice.toFixed(2)}
          </T8y>
        )}
      </div>
    </div>
  );
};

export default MainProductPrice;
