import React, { useCallback } from 'react';
import { forceVisible } from 'react-lazyload';

import { ReactComponent as MessageIcon } from 'assets/images/icons/message.svg';

import style from './message.module.scss';

const Message = () => {
  const currElementId = 'enquiryForm';

  const scrollToEnquiry = useCallback(() => {
    forceVisible();

    setTimeout(() => {
      const el = document.getElementById(currElementId);

      if (!el) {
        return;
      }

      const elementPosition = el.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 150;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }, 600);
  }, [currElementId]);

  return (
    <MessageIcon onClick={scrollToEnquiry} className={style.messageIcon} />
  );
};

export default Message;
