import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import { SMALL_SCREEN } from 'consts';
import { useStore } from 'effector-react';
import { useMatchMedia } from 'hooks/useMatchMedia';
import MobileDropDown from 'layout/Footer/MobileDropDown';
import { products$ } from 'models/product';

import DesktopCategoriesList from './DesktopCategoriesList';

import { T8y } from 'ui';

import style from 'layout/Footer/commonFooterStyles.module.scss';

const Categories = () => {
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const products = useStore(products$);

  return isSmallScreen ? (
    <MobileDropDown title="Hire by skip size">
      {products.map(({ name, slug }, i) => (
        <T8y
          key={i}
          as={Link}
          to={PATHS.PRODUCT(slug)}
          variant="t1v1"
          color="primary"
          className={style.footerLink}
        >
          {name}
        </T8y>
      ))}
    </MobileDropDown>
  ) : (
    <DesktopCategoriesList />
  );
};

export default Categories;
