import React from 'react';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { isMenuOpen$ } from 'models/common';
import { isFixedReservationBannerVisible$ } from 'models/product';

import AttachmentTop from './AttachementTop';
import Categories from './Categories';
import Information from './Information';
import PoweredBy from './PoweredBy';
import Regions from './Regions';

import { Row, T8y } from 'ui';

import { ReactComponent as Watsapp } from 'assets/images/icons/watsapp.svg';

import style from './footer.module.scss';

const currentYear = new Date().getFullYear();

export const Footer = () => {
  const isMenuOpen = useStore(isMenuOpen$);
  const isFixedReservationBannerVisible = useStore(
    isFixedReservationBannerVisible$,
  );

  return (
    <footer>
      <AttachmentTop />
      <Row
        justify="center"
        smDirection="column"
        className={style.footerAttachmentContainer}
      >
        <div className={style.footerColumn}>
          <Categories />
        </div>
        <div className={style.footerColumn}>
          <Information />
        </div>
        <div className={style.footerColumn}>
          <Regions />
        </div>
        <div className={style.footerColumn}>
          <PoweredBy />
        </div>
      </Row>

      <div className={style.copyright}>
        <T8y
          as="a"
          href="https://www.wilko.com"
          rel="noopener noreferrer"
          variant="t1v1"
        >
          Back to wilko.com
        </T8y>
        <T8y variant="t1v1" mt>
          Copyright © {currentYear} easyToolhire & wilko. All rights reserved.
        </T8y>
      </div>
      {!isMenuOpen && (
        <T8y
          as="a"
          className={clsx(
            style.whatsappIcon,
            style.rightPosition,
            isFixedReservationBannerVisible && style.chatWithReservationPanel,
          )}
          href={'https://wa.me/447441350385'}
          target="_blank"
          aria-label="Whatsapp"
        >
          <Watsapp />
        </T8y>
      )}
    </footer>
  );
};
