import { useCallback, useMemo } from 'react';
import { useStore } from 'effector-react';
import { appliedPromoCode$, cartFormattedData$ } from 'models/cart';

export const useGetCommonCheckoutData = ({ details, subscription }) => {
  const appliedPromoCode = useStore(appliedPromoCode$);
  const cartFormattedData = useStore(cartFormattedData$);

  const getFormattedCheckoutData = useCallback(() => {
    return {
      subscription,
      promocode: appliedPromoCode || undefined,
      insuranceType: details.insurance,
      userName: details.name,
      userEmail: details.email,
      userPhone: details.phone,
      userIsCompany: false,
      userCompany: null,
      reference: '',
      products: cartFormattedData,
      payment_method: details.paymentMethod || undefined,
    };
  }, [details, subscription, appliedPromoCode, cartFormattedData]);

  return useMemo(() => getFormattedCheckoutData, [getFormattedCheckoutData]);
};
