import { PATHS } from 'AppPaths';
import { createEvent, createStore } from 'effector';

export const setBreadCrumbs = createEvent();
export const removeBreadCrumbs = createEvent();
export const setBreadCrumbsIsLoading = createEvent();

export const breadCrumbsIsLoading$ = createStore(false).on(
  setBreadCrumbsIsLoading,
  (_, p) => p,
);

export const breadCrumbs$ = createStore(null)
  .on(setBreadCrumbs, (s, { checkout, products, isProductMobile, product }) => {
    return {
      home: {
        name: 'Home',
        link: '/',
        isExternalLink: true,
        isProductMobile,
      },
      ...(products && {
        allProducts: {
          name: 'Skip hire',
          link: PATHS.CATEGORY,
        },
        ...(checkout && {
          checkout: {
            name: checkout.checkout,
            link: PATHS.RESERVATION,
          },
        }),

        ...(product && {
          product: {
            link: PATHS.PRODUCT(product.slug),
            presetType: 'product',
            name: product.name,
          },
        }),
      }),
    };
  })
  .on(removeBreadCrumbs, () => null);
