import React from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import clsx from 'clsx';

import 'react-phone-number-input/style.css';
import style from './phoneField.module.scss';

export const PhoneField = ({
  className,
  placeholder,
  value,
  setValue,
  control,
  name,
  ...rest
}) => {
  return (
    <PhoneInputWithCountry
      name={name || 'phone'}
      rules={{
        validate: {
          minLength: (value) => value?.trim().length > 5 || 'Min. 6 characters',
          rightLength: (value) => {
            return isPossiblePhoneNumber(value) || 'Incorrect phone number';
          },
        },
      }}
      defaultValue={value}
      control={control}
      type="tel"
      defaultCountry="GB"
      value={value}
      onChange={setValue}
      placeholder={placeholder}
      autoComplete="shipping tel"
      className={clsx(style.phoneCountryInput, className)}
      {...rest}
      international
      limitMaxLength
    />
  );
};
