export const mainPageTopCategoriesSlider = {
  responsive: [
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1070,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

export const mainPagePopularSlider = {
  responsive: [
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1070,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};
